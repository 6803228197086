import { memo, MouseEventHandler } from "react";

import ButtonClose from "../../Atoms/Button/ButtonClose";
import DrawerTitle from "../../Atoms/Drawer/DrawerTitle";

interface DrawerHeaderProps {
    title: string;
    close: MouseEventHandler<HTMLButtonElement>;
}

const DrawerHeader = ({ title, close }: DrawerHeaderProps): JSX.Element => (
    <header className="drawer__header">
        <DrawerTitle title={title} />

        <ButtonClose onClick={close} />
    </header>
);

export default memo(DrawerHeader);
