import { ReactNode, memo } from "react";

interface NavigationLinkListProps {
    children: ReactNode;
}

const NavigationLinkList = ({
    children
}: NavigationLinkListProps): JSX.Element => (
    <ul className="navigation__link-list">{children}</ul>
);

export default memo(NavigationLinkList);
