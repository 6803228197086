// -----------------------------------------------------
// Dependencies
// -----------------------------------------------------
import { memo, ReactNode } from "react";

// -----------------------------------------------------
// Typescript rules
// -----------------------------------------------------
interface SettingTableRowProps {
    label: string;
    customLabel?: string;
    content: string | ReactNode;
}

// -----------------------------------------------------
// Main component
// -----------------------------------------------------
const SettingTableRow = ({
    label,
    customLabel,
    content
}: SettingTableRowProps): JSX.Element => (
    <div className="settingTable__row">
        <span className="settingTable__key">
            {customLabel ? customLabel : label}
        </span>
        <span className="settingTable__value medium">
            {parseContent(content)}
        </span>
    </div>
);

export default memo(SettingTableRow);

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------
const capitalizeText = (str) => str.charAt(0).toUpperCase() + str.slice(1);
const isDate = (str) => Date.parse(str);
const convertDate = (strDate) => new Date(strDate).toLocaleDateString();
const parseContent = (str) => {
    if (!isNaN(isDate(str))) {
        return convertDate(str);
    }

    if (typeof str === "boolean") {
        return str.toString();
    }

    return str;
};

type CustomLabelType = {
    [key: string]: string;
};

export const renderTableRows = (
    details,
    customLabels?: CustomLabelType
): JSX.Element[] => {
    let tableRows = [];

    for (const key in details) {
        if (details[key] !== null && details[key] !== "") {
            let customLabel;

            if (customLabels && customLabels[key]) {
                customLabel = customLabels[key];
            }

            tableRows.push(
                <SettingTableRow
                    key={key}
                    label={capitalizeText(key.replace("_", " "))}
                    customLabel={customLabel}
                    content={details[key]}
                />
            );
        }
    }

    return tableRows;
};
