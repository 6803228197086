import { ReactNode, memo } from "react";
import FAIcon from "../../components/Atoms/Icons/FAIcon";
import LoginContentSection from "../../components/Layouts/LoginContentSection/LoginContentSection";
import LoginPageLayout from "../../components/Layouts/LoginPageLayout/LoginPageLayout";

interface SuccessContentProps {
    title: string;
    subtitle: string;
    bottomContent?: ReactNode | string;
}

const SuccessContent = ({
    title = "Page title",
    subtitle = "Page subtitle",
    bottomContent = `Html or JSX bottom content`
}: SuccessContentProps): JSX.Element => (
    <LoginPageLayout className="successPage">
        <LoginContentSection title={title}>
            <FAIcon
                icon="fa-duotone fa-circle-check"
                className="login__icon-success"
            />

            <p
                className="formText"
                dangerouslySetInnerHTML={{ __html: subtitle }}
            />

            {bottomContent && <p className="formText">{bottomContent}</p>}
        </LoginContentSection>
    </LoginPageLayout>
);

export default memo(SuccessContent);
