import { useMemo, memo } from "react";
import { LicenseOutput } from "../../../api/LicenseController";
import Tooltip from "../../../components/Molecules/Tooltip/Tooltip";
import { classList } from "../../../helpers/className";

type StatusType = "Expired" | "Not valid yet" | "Pending" | "Active";

const statusClasses: Record<StatusType, string> = {
    Expired: "expired",
    "Not valid yet": "not-valid",
    Pending: "pending",
    Active: "active"
};

interface LicenseStatusProps {
    license: LicenseOutput;
}

const LicenseStatus = ({ license }: LicenseStatusProps) => {
    const { valid_from, expires_at, install_count } = license;
    const today = new Date().valueOf();
    const start = new Date(valid_from).valueOf();
    const expiration = new Date(expires_at).valueOf();

    const status: StatusType = useMemo(
        () =>
            today > expiration
                ? "Expired"
                : start > today
                ? "Not valid yet"
                : install_count === (null || undefined || 0)
                ? "Pending"
                : "Active",
        [expiration, today, start, install_count]
    );

    return (
        <span
            className={classList(
                "table__status-content",
                statusClasses[status]
            )}
        >
            {(status === "Pending" && (
                <Tooltip content="Get activation key from TheHive">
                    {status}
                </Tooltip>
            )) ||
                status}
        </span>
    );
};

export default memo(LicenseStatus);
