type GenericDetailTableDataType = object;

interface GenericDetailTableProps {
    data: GenericDetailTableDataType;
}

const GenericDetailTable = ({ data }: GenericDetailTableProps): JSX.Element => {
    const _renderTableRow = (data) => {
        let tableContent = [];

        for (const [key, value] of Object.entries(data)) {
            tableContent.push(
                <tr className="licenseDetail__tableRow" key={key}>
                    <td className="licenseDetail__tableLabel">{key}</td>
                    <td className="licenseDetail__tableValue">
                        {value.toString()}
                    </td>
                </tr>
            );
        }

        return tableContent;
    };

    return (
        <table className="licenseDetail__table">
            <tbody>{_renderTableRow(data)}</tbody>
        </table>
    );
};

export default GenericDetailTable;
