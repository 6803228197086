import { LicenseOutput } from "../api/LicenseController";

export type StatusType = "Expired" | "Not valid yet" | "Pending" | "Active";

export const getLicenseStatus = (license: LicenseOutput): StatusType => {
    const { valid_from, expires_at, install_count } = license;
    const today = new Date().valueOf();
    const start = new Date(valid_from).valueOf();
    const expiration = new Date(expires_at).valueOf();

    const status: StatusType =
        today > expiration
            ? "Expired"
            : start > today
            ? "Not valid yet"
            : install_count === (null || undefined || 0)
            ? "Pending"
            : "Active";

    return status;
};
