import { ReactElement, memo } from "react";
import { classList } from "../../../helpers/className";
import { iconForbidden } from "../../../images/pictograms";
import FAIcon from "../Icons/FAIcon";
import IconForbidden from "../Icons/IconForbidden";
import "./Button.scss";

interface ButtonProps {
    onClick?: any;
    children: string | string[] | ReactElement;
    className?: string | string[];
    url?: string;
    icon?: string;
    disabled?: boolean;
    disabledText?: string;
    rounded?: boolean;
}

const Button = ({
    children,
    className = "button",
    url,
    icon,
    onClick,
    disabled,
    disabledText,
    rounded
}: ButtonProps) => {
    const content = (
        <>
            {icon && <FAIcon icon={icon} className="button-icon" />}
            {children}
        </>
    );
    if (url) {
        return (
            <a href={url} className={classList(className)}>
                {content}
            </a>
        );
    }
    return (
        <button
            className={classList(className, { rounded, disabled })}
            onClick={onClick}
            disabled={disabled}
        >
            {(!disabled && content) || disabledText || content}
            {disabled && (
                <FAIcon icon={iconForbidden} className="button-iconForbidden" />
            )}
        </button>
    );
};

export default memo(Button);
