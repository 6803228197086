import ParentController from "./ParentController";

export interface LicenseOutput {
    created_at: string;
    created_by: string;
    updated_at: string;
    updated_by: string;

    id: string;
    customer: string;
    instance?: object;
    instanceData?: object;
    product: string;
    plan: string;
    valid_from: string;
    expires_at: string;
    capabilities: string[];
    quotas?: Record<string, number>;
    kind: string;
    description?: string;

    // only for superadmin
    locked: boolean;
    install_count: number;
}

export class LicenseController extends ParentController {
    endpoints = {
        getAll: "/license/",
        get: "/license/:id/",
        generateKey: "/license/:licenseId/generate/",
        requestTrial: "/license/request_trial/",
        requestCommunity: "/license/request_community/",
        updateDescription: "/license/:id"

        // create: "/license",
        // delete: "/license/:id",
    };

    async getAll(): Promise<LicenseOutput[]> {
        const { data } = await this.manager.get<LicenseOutput[]>(
            this.endpoints.getAll
        );

        return data;
    }

    async getOne(licenseId: string): Promise<LicenseOutput> {
        const url = this._interpolate(this.endpoints.get, { id: licenseId });
        const { data } = await this.manager.get<LicenseOutput>(url);

        return data;
    }

    async generateKey(licenseId: string, challenge: string): Promise<string> {
        const url = this._interpolate(this.endpoints.generateKey, {
            licenseId
        });

        const { data } = await this.manager.post<string>(url, {
            data: challenge
        });

        return data;
    }

    async requestTrial() {
        const url = this.endpoints.requestTrial;

        const { data } = await this.manager.get(url);

        return data;
    }

    async requestCommunity() {
        const url = this.endpoints.requestCommunity;

        const { data } = await this.manager.get(url);

        return data;
    }

    async updateDescription(licenseId: string, description): Promise<string> {
        const url = this._interpolate(this.endpoints.updateDescription, {
            id: licenseId
        });

        const { data } = await this.manager.patch<string>(url, description);

        return data;
    }
}
