import { memo } from "react";
import { logoStrangebeeCurrent } from "../../images/logos";

interface LoginLogoProps {
    logo?: string;
}

const LoginLogo = ({ logo = logoStrangebeeCurrent }: LoginLogoProps) => (
    <img src={logo} alt="Logo StrangeBee" className="login__logo" height={63} />
);

export default memo(LoginLogo);
