import { useContext, useMemo, memo, useEffect } from "react";
import HeaderUser from "../../../components/Atoms/Header/HeaderUser/HeaderUser";

import { logoStrangebeeCurrent } from "../../../images/logos";
import { ScopeContext } from "../../../context/ScopeProvider";

// * FOR NEXT VERSION
// import HeaderAlert from "../../../components/Atoms/Header/HeaderAlert/HeaderAlert";
// import placeholderPicture from "../../../images/user/avatar.svg";
// * END FOR NEXT VERSION

import "./Header.scss";
import ButtonCta from "./ButtonCta";

const Header = () => {
    const { user, isPremiumUser } = useContext(ScopeContext);

    const fullName = useMemo(
        () =>
            user?.firstname
                ? `${user?.firstname} ${user?.lastname}`
                : "Loading...",
        [user?.firstname, user?.lastname]
    );

    return (
        <header className="header">
            <div className="header__content container">
                <img
                    src={logoStrangebeeCurrent}
                    alt="StrangeBee logo"
                    className="header__logo"
                />

                <div className="header__right">
                    {/* <HeaderAlert /> */}
                    {isPremiumUser && (
                        <ButtonCta
                            url={process.env.REACT_APP_SUPPORT_URL}
                            buttonText="Contact support"
                            target="_blank"
                            rel="noreferrer"
                        />
                    )}

                    <HeaderUser userName={fullName} />
                </div>
            </div>
        </header>
    );
};

export default memo(Header);
