import { memo } from "react";
import { navigationLinkType } from "../../../../content/Dashboard/Navigation/navigationTabs";
import FAIcon from "../../Icons/FAIcon";

interface NavigationLinkProps {
    link: navigationLinkType;
    action?: any;
}

const NavigationLink = ({ link, action }: NavigationLinkProps): JSX.Element => (
    <li className="navigation__link" onClick={action}>
        <FAIcon icon={link.icon} />
        <span className="navigation__linkLabel">{link.label}</span>
    </li>
);

export default memo(NavigationLink);
