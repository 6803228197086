export const iconArrowLeft = "fa-light fa-arrow-left";
export const iconBell = "fa-regular fa-bell";
export const iconBuilding = "fa-regular fa-building-columns";
export const iconCake = "fa-regular fa-cake-candles";
export const iconChevronLeft = "fa-regular fa-chevron-left";
export const iconChevronRight = "fa-regular fa-chevron-right";
export const iconCircleChecked = "fa-duotone fa-circle-check";
export const iconCommunity = "fa-regular fa-circle-nodes";
export const iconSlackUsers = "fa-regular fa-users";
export const iconCopy = "fa-regular fa-copy";
export const iconCrossWhite = "fa-regular fa-xmark";
export const iconCloseCircle = "fa-regular fa-circle-xmark";
export const iconEdit = "fa-regular fa-pen";
export const iconError = "fa-regular fa-triangle-exclamation";
export const iconEye = "fa-regular fa-eye";
export const iconEyeSlash = "fa-regular fa-eye-slash";
export const iconFlag = "fa-light fa-flag";
export const iconForbidden = "fa-regular fa-ban";
export const iconShare = "fa-regular fa-share-nodes";
export const iconCommments = "fa-regular fa-comment-dots";
export const iconHome = "fa-light fa-house";
export const iconJournal = "fa-regular fa-book";
export const iconLicense = "fa-light fa-file-shield";
export const iconLock = "fa-regular fa-lock-keyhole";
export const iconLogout = "fa-regular fa-power-off";
export const iconMail = "fa-regular fa-envelope";
export const iconNewUser = "fa-regular fa-user-plus";
export const iconQuestion = "fa-light fa-circle-question";
export const iconRenew = "fa-regular fa-arrows-spin";
export const iconSettings = "fa-light fa-gear";
export const iconStars = "fa-solid fa-stars";
export const iconTrash = "fa-regular fa-trash";
export const iconUser = "fa-regular fa-user";
export const iconUsers = "fa-light fa-user-group";
export const iconUnselect = "fa-regular fa-square-minus";
export const iconWebsite = "fa-regular fa-globe";
