import { AxiosError } from "axios";
import { QRCodeSVG } from "qrcode.react";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";

import CodeValidationForm from "../../../components/Molecules/LoginForm/CodeValidationForm";
import DrawerLayout from "../../../components/Layouts/DrawerLayout/DrawerLayout";
import QRCodeContainer from "../../../components/Layouts/QRCode/QRCodeContainer";

import { CLIENT } from "../../../api/client";
import { MSG } from "../../../data/msg";
import { queryClient } from "../../..";
import QRCodeUrl from "../../../components/Molecules/QRCode/QRCodeUrl";

interface DrawerOTPProps {
    isOpen: boolean;
    setIsOpen: Function;
}

const DrawerOTP = ({ isOpen, setIsOpen }: DrawerOTPProps): JSX.Element => {
    const [errMsg, setErrMsg] = useState<string | null>();
    const [copySuccess, setCopySuccess] = useState<string | null>();
    const [url, setUrl] = useState("");

    const { mutate, error, reset, isLoading, isSuccess } = useMutation<
        any,
        Error,
        any
    >((token) => CLIENT.LogController.otpActivate(token), {
        onSuccess: () => {
            queryClient.refetchQueries(["get-current"]);

            setTimeout(() => {
                setIsOpen(false);
                resetDatas();
            }, 2000);
        },
        onError: (err: AxiosError) => {
            if (err?.response.status === 500) {
                setErrMsg(MSG.err500);
            }

            if (err?.response.status === 400) {
                setErrMsg("Unable to activate Two-Factor Authentication");
            }
        }
    });

    const resetDatas = () => {
        reset();
        setErrMsg(null);
    };

    const onCodeFormSubmit = (token) => {
        resetDatas();
        mutate(token);
    };

    const onCopySuccess = () => {
        setCopySuccess("Url successfully copied");

        setTimeout(() => {
            setCopySuccess(null);
        }, 1000);
    };
    useEffect(() => {
        if (isOpen) {
            CLIENT.LogController.otpGetUrl()
                .then((res) => setUrl(res.data))
                .catch((err) => {
                    console.error(err);
                    setErrMsg(
                        "Unable to set Two-Factor Authentication. Please try later"
                    );
                });
        }
    }, [isOpen]);

    return (
        <DrawerLayout
            title="Flash the QR Code"
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            reset={resetDatas}
            error={errMsg ? errMsg : error?.message}
            success={
                isSuccess
                    ? "Two-Factor Authentication has been activated"
                    : copySuccess
                    ? copySuccess
                    : ""
            }
            content={
                url && (
                    <>
                        <QRCodeContainer>
                            <QRCodeSVG value={url} size={230} />
                            <QRCodeUrl url={url} callback={onCopySuccess} />
                        </QRCodeContainer>

                        <CodeValidationForm
                            onFormSubmit={onCodeFormSubmit}
                            buttonClassName="button--block"
                            inputText="MFA Code *"
                            isLoading={isLoading}
                            isSuccess={isSuccess}
                            isOpen={isOpen}
                            buttonText="Save"
                        />
                    </>
                )
            }
        />
    );
};

export default DrawerOTP;
