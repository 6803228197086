import ParentController from "./ParentController";

export class CollectedDataController extends ParentController {
    endpoints = {
        create: "/data",
        get: "/data/:id",
        edit: "/data/:id",
        delete: "/data/:id"
    };
}
