import ParentController from "./ParentController";

export class InstanceController extends ParentController {
    endpoints = {
        create: "/instance",
        get: "/instance/:id",
        edit: "/instance/:id",
        delete: "/instance/:id",

        getInstance: "/instance/:id/instance",
        getLicense: "/instance/:id/license",
        getData: "/instance/:id/data"
    };

    getInstance(id) {
        const url = this._interpolate(this.endpoints.getInstance, { id });
        return this.getDataFrom(url);
    }

    getLicense(id) {
        const url = this._interpolate(this.endpoints.getLicense, { id });
        return this.getDataFrom(url);
    }

    getData(id) {
        const url = this._interpolate(this.endpoints.getData, { id });
        return this.getDataFrom(url);
    }
}
