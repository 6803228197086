import React from "react";
import { classList } from "../../../helpers/className";
import "./LoadingScreen.scss";

interface LoadingScreenProps {
    className?: string | string[];
    contained?: boolean;
}

const LoadingScreen = ({
    className,
    contained
}: LoadingScreenProps): JSX.Element => (
    <div className={classList("loadingScreen", className, { contained })}></div>
);

export default React.memo(LoadingScreen);
