// -----------------------------------------------------
// dependencies
// -----------------------------------------------------
import { useState, memo } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import useDisabledButton from "../../../hooks/useDisabledButton";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import YupPassword from "yup-password";
import ReCAPTCHA from "react-google-recaptcha";

import FormInput from "../../Atoms/Form/Input/Input";
import Button from "../../Atoms/Button/Button";

import { iconUser, iconMail, iconBuilding } from "../../../images/pictograms";

import "./RegisterForm.scss";
import { classList } from "../../../helpers/className";
import LoadingScreen from "../../Layouts/LoadingScreen/LoadingScreen";

YupPassword(yup);

// -----------------------------------------------------
// typescript rules
// -----------------------------------------------------
interface RegisterFormProps {
    onFormSubmit: Function;
    isLoading?: boolean;
}

export type RegisterFormValuesType = {
    firstname: string;
    lastname: string;
    email: string;
    title: string | null;
    company_name: string;
    requirement: string;
};

// -----------------------------------------------------
// form validation
// -----------------------------------------------------
const formSchema = yup.object().shape({
    firstname: yup
        .string()
        .min(2, "Firstname should at least be 2 characters")
        .required("Firstname is required"),
    lastname: yup
        .string()
        .min(2, "Lastname should at least be 2 characters")
        .required("Lastname is required"),
    email: yup
        .string()
        .email("It should be a valid email address")
        .lowercase("Your email adress should be to lower case")
        .required("Email is required"),
    title: yup
        .string()
        .min(2, "Title should at least be 2 characters")
        .required("Title is required"),
    company_name: yup
        .string()
        .min(2, "Company name should at least be 2 characters")
        .required("Company name is required"),
    requirement: yup
        .string()
        .min(2, "Requiement should at least be 2 characters")
        .required("Requirement details are mandatory")
});

// -----------------------------------------------------
// component
// -----------------------------------------------------
const RegisterForm = ({
    onFormSubmit,
    isLoading
}: RegisterFormProps): JSX.Element => {
    const [token, setToken] = useState<string>();
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors }
    } = useForm<RegisterFormValuesType>({ resolver: yupResolver(formSchema) });

    const { firstname, lastname, email, title, company_name, requirement } =
        watch();
    const { isButtonDisabled } = useDisabledButton(
        [firstname, lastname, email, title, company_name, requirement, token],
        true
    );

    const handleFormSubmit = (values: RegisterFormValuesType) => {
        onFormSubmit(values);
    };

    const handleToken = async (value) => await setToken(value);

    return (
        <form
            autoComplete="off"
            className={classList("registerForm")}
            onSubmit={handleSubmit((data) => handleFormSubmit(data))}
        >
            <div className="registerForm__rowInline">
                <FormInput
                    name="firstname"
                    placeholder="First Name"
                    className={classList({ error: !!errors.firstname })}
                    register={register}
                    icon={iconUser}
                    required
                    error={errors.firstname?.message}
                />
                <FormInput
                    name="lastname"
                    placeholder="Last Name"
                    className={classList({ error: !!errors.lastname })}
                    register={register}
                    icon={iconUser}
                    required
                    error={errors.lastname?.message}
                />
            </div>
            <FormInput
                name="email"
                type="email"
                placeholder="Email"
                className={classList({ error: !!errors.email })}
                register={register}
                icon={iconMail}
                required
                error={errors.email?.message}
            />

            <div className="registerForm__rowInline">
                <FormInput
                    name="title"
                    placeholder="Title"
                    className={classList({ error: !!errors.title })}
                    register={register}
                    icon={iconBuilding}
                    required
                    error={errors.title?.message}
                />
                <FormInput
                    name="company_name"
                    placeholder="Company Name"
                    className={classList({ error: !!errors.company_name })}
                    register={register}
                    icon={iconBuilding}
                    required
                    error={errors.company_name?.message}
                />
            </div>

            <FormInput
                type="textarea"
                name="requirement"
                placeholder="Tell us about your project"
                className={classList({ error: !!errors.requirement })}
                register={register}
                required
                error={errors.requirement?.message}
            />

            <p className="formParagraph">
                By clicking on Register, you accept to receive emails from
                StrangeBee in order to manage your access to the License Portal.
            </p>

            <div className="registerForm__row">
                <Link to="/login" className="formLink">
                    I already have an account
                </Link>

                <Button className="button--primary" disabled={isButtonDisabled}>
                    {isLoading ? <LoadingScreen contained /> : "Register"}
                </Button>
            </div>

            <div className="loginForm__row--center">
                <ReCAPTCHA
                    sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY}
                    onChange={handleToken}
                />
            </div>
        </form>
    );
};

export default memo(RegisterForm);
