import {
    logoTheHiveCurrentTiny as theHive,
    logoCortexCurrentTiny as cortex,
    logoTheHiveCloudTiny as theHiveCloud
} from "../images/logos";

export const PRODUCTS = {
    cortex: {
        name: "Cortex",
        icon: cortex
    },
    thehive: {
        name: "TheHive",
        icon: theHive
    },
    thehivecloud: {
        name: "TheHiveCloud",
        icon: theHiveCloud
    }
};

export const renderProductIcon = (productName: string): string => {
    const productId = productName.toLowerCase();

    if (PRODUCTS[productId]) {
        return PRODUCTS[productId].icon;
    }

    return "";
};
