// ----------------------------------------
// Dependencies
// ----------------------------------------
import { useState, memo, useEffect } from "react";
import { useMutation } from "react-query";
import { AxiosError } from "axios";

import RegisterContent from "../../content/Login/RegisterContent";
import SuccessContent from "../../content/Login/SuccessContent";

import { RegisterFormValuesType } from "../../components/Molecules/LoginForm/RegisterForm";
import { CLIENT } from "../../api/client";
import { URLS } from "../../api/urls";

import "./RegisterPage.scss";
import "./SuccessPage";
import { MSG } from "../../data/msg";

// ----------------------------------------
// Main component
// ----------------------------------------
const RegisterPage = () => {
    const [email, setEmail] = useState<string>();
    const [errMsg, setErrMsg] = useState<string | null>();
    const { mutate, error, isLoading, isSuccess, reset } = useMutation<
        any,
        Error,
        RegisterFormValuesType
    >(
        (formValues: RegisterFormValuesType) =>
            CLIENT.UserController.create_registration_request(formValues),
        {
            onError: (err: AxiosError) => {
                switch (err?.response.status) {
                    case 400:
                        setErrMsg(MSG.err400);
                        return;
                    case 401:
                        setErrMsg(MSG.err401);
                        return;
                    case 500:
                        setErrMsg(MSG.err500);
                        return;
                    default:
                        setErrMsg(err?.response.data.message);
                }
            }
        }
    );

    const onFormSubmit = (formValues: RegisterFormValuesType) => {
        setEmail(formValues.email);
        reset();
        setErrMsg(null);
        mutate(formValues);
    };

    return (
        (!isSuccess && (
            <RegisterContent
                isLoading={isLoading}
                error={errMsg ? errMsg : error?.message}
                onFormSubmit={onFormSubmit}
            />
        )) || (
            <SuccessContent
                title="Check your inbox"
                subtitle={`
                Thank you for your registration. <br/>
                You will receive an email to confirm your address in a short moment. If not, please check your junk folder.
                `}
                bottomContent={
                    <>
                        Still need help?{" "}
                        <a
                            href={URLS.website.contactPage}
                            className="formLink"
                            target="_blank"
                            rel="noreferrer "
                        >
                            Contact us
                        </a>
                    </>
                }
            />
        )
    );
};

export default memo(RegisterPage);
