// ----------------------------------------
// Dependencies
// ----------------------------------------
import { useEffect, useState } from "react";

import Alert from "../../../components/Molecules/Alert/Alert";
import ButtonLink from "../../../components/Atoms/Button/ButtonLink";
import DrawerCommunityLicense from "../Drawer/DrawerCommunityLicense";
import DrawerTrialLicense from "../Drawer/DrawerTrialLicense";
import ErrorScreen from "../../../components/Layouts/ErrorScreen/ErrorScreen";
import LicenseTableHeader from "../../../components/Layouts/Table/LicenseTableHeader";
import LicenseTableRow from "../../../components/Layouts/Table/LicenseTableRow";
import LoadingScreen from "../../../components/Layouts/LoadingScreen/LoadingScreen";
import TableLayout from "../../../components/Layouts/Table/TableLayout";
import TableTopRow from "../../../components/Atoms/Table/TableTopRow";

import { LicenseOutput } from "../../../api/LicenseController";
import { iconCommunity, iconError } from "../../../images/pictograms";

import "./LicenseTable.scss";
import FAIcon from "../../../components/Atoms/Icons/FAIcon";
import { CLIENT } from "../../../api/client";

// ----------------------------------------
// Main component
// ----------------------------------------
interface LicenseTableProps {
    licenses: LicenseOutput[];
    status: "idle" | "error" | "loading" | "success";
    isFetching: boolean;
    isLoading: boolean;
    fetchLicensesError: any;
    refetch: Function;
    isTrialDrawerOpen: boolean;
    setIsTrialDrawerOpen: Function;
    isCommunityDrawerOpen: boolean;
    setIsCommunityDrawerOpen: Function;
}

const LicenseTable = ({
    licenses,
    status,
    isFetching,
    isLoading,
    fetchLicensesError,
    refetch,
    isTrialDrawerOpen,
    setIsTrialDrawerOpen,
    isCommunityDrawerOpen,
    setIsCommunityDrawerOpen
}: LicenseTableProps): JSX.Element => {
    const [error] = useState<string>();

    const content = licenses.map((license) => (
        <LicenseTableRow key={license.id} license={license} />
    ));

    const onRequestTrial = () => setIsTrialDrawerOpen(true);
    const onRequestCommunity = () => setIsCommunityDrawerOpen(true);

    useEffect(() => {
        refetch();
    }, [refetch, licenses]);

    if (status === "loading" || isFetching || isLoading) {
        return <LoadingScreen />;
    }

    return (
        <>
            {error && <Alert className="danger" content={error} closable />}
            {(fetchLicensesError && (
                <Alert
                    className={["danger", "center"]}
                    content="Unable to get your license list"
                />
            )) || (
                <>
                    {licenses?.length > 0 && (
                        <TableTopRow>
                            <ButtonLink
                                title="Request a Trial"
                                onClick={onRequestTrial}
                            />

                            <ButtonLink
                                title="Register a community license"
                                icon={iconCommunity}
                                onClick={onRequestCommunity}
                            />
                        </TableTopRow>
                    )}
                    {licenses?.length > 0 ? (
                        <TableLayout
                            header={<LicenseTableHeader />}
                            content={content}
                        />
                    ) : (
                        <ErrorScreen
                            message="You have no license."
                            icon={<FAIcon icon={iconError} className="icon" />}
                        >
                            <p className="errorScreen__message">
                                <ButtonLink
                                    title="Get a trial"
                                    onClick={onRequestTrial}
                                />{" "}
                                or{" "}
                                <ButtonLink
                                    title="register a community"
                                    onClick={onRequestCommunity}
                                />
                            </p>
                        </ErrorScreen>
                    )}

                    <DrawerTrialLicense
                        isOpen={isTrialDrawerOpen}
                        setIsOpen={setIsTrialDrawerOpen}
                    />

                    <DrawerCommunityLicense
                        isOpen={isCommunityDrawerOpen}
                        setIsOpen={setIsCommunityDrawerOpen}
                    />
                </>
            )}
        </>
    );
};

export default LicenseTable;
