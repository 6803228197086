import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { CLIENT } from "../../api/client";
import LicenseDetail from "../../content/Dashboard/LicenseDetail/LicenseDetail";
import { DrawerProvider } from "../../context/DrawerContext";

const LicensePage = () => {
    const { licenseId } = useParams();
    const navigate = useNavigate();

    const {
        data: licenseData,
        refetch,
        isLoading
    } = useQuery({
        queryFn: ({ queryKey }) => CLIENT.LicenseController.getOne(queryKey[1]),
        queryKey: ["get-license", licenseId],
        refetchOnMount: true,
        onError: () => navigate("/portal/licenses")
    });

    const props = { licenseId, licenseData, refetch, isLoading };

    return (
        <DrawerProvider>
            {(params) => (
                <LicenseDetail
                    isOpen={params.isOpen}
                    setIsOpen={params.setIsOpen}
                    {...props}
                />
            )}
        </DrawerProvider>
    );
};

export default LicensePage;
