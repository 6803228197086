// -----------------------------------------------------
// dependencies
// -----------------------------------------------------
import { useState, memo } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import YupPassword from "yup-password";
import ReCAPTCHA from "react-google-recaptcha";
import useDisabledButton from "../../../hooks/useDisabledButton";

import FormInput from "../../Atoms/Form/Input/Input";
import Button from "../../Atoms/Button/Button";
import { iconLock } from "../../../images/pictograms";

import "./LoginForm.scss";
import { classList } from "../../../helpers/className";
import LoadingScreen from "../../Layouts/LoadingScreen/LoadingScreen";

YupPassword(yup);

// -----------------------------------------------------
// typescript rules
// -----------------------------------------------------
interface AccountActivationFormProps {
    onFormSubmit: Function;
    isLoading?: boolean;
}

export type AccountActivationFormValuesTypes = {
    password: string;
    password2: string;
};

// -----------------------------------------------------
// form validation
// -----------------------------------------------------
const formSchema = yup.object().shape({
    password: yup.string().password().required("Password is required"),
    password2: yup
        .string()
        .required("Password confirmation is required")
        .oneOf([yup.ref("password")], "Password must match")
});

// -----------------------------------------------------
// component
// -----------------------------------------------------
const AccountActivationForm = ({
    onFormSubmit,
    isLoading
}: AccountActivationFormProps): JSX.Element => {
    const [token, setToken] = useState<string>();
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors }
    } = useForm<AccountActivationFormValuesTypes>({
        resolver: yupResolver(formSchema)
    });

    const handleFormSubmit = (values: AccountActivationFormValuesTypes) => {
        onFormSubmit(values);
    };

    const handleToken = async (value) => await setToken(value);

    const { password, password2 } = watch();
    const { isButtonDisabled } = useDisabledButton(
        [password, password2, token],
        true
    );

    return (
        <form
            autoComplete="off"
            className="loginForm"
            onSubmit={handleSubmit((data) => handleFormSubmit(data))}
        >
            <FormInput
                type="password"
                name="password"
                icon={iconLock}
                placeholder="Password"
                className={classList({ error: !!errors.password })}
                error={errors.password?.message}
                register={register}
            />
            <FormInput
                type="password"
                name="password2"
                icon={iconLock}
                placeholder="Confirm password"
                className={classList({ error: !!errors.password2 })}
                error={errors.password2?.message}
                register={register}
            />

            <div className="loginForm__row">
                <Link to="/" className="button">
                    Cancel
                </Link>
                <Button className="button--primary" disabled={isButtonDisabled}>
                    {isLoading ? <LoadingScreen contained /> : "Confirm"}
                </Button>
            </div>

            <div className="loginForm__row--center">
                <ReCAPTCHA
                    sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY}
                    onChange={handleToken}
                />
            </div>
        </form>
    );
};

export default memo(AccountActivationForm);
