import ParentController from "./ParentController";

export interface CustomerOutput {
    id: string;
    name: string;
    contact_name?: string;
    contact_email?: string;
    contact_firstname?: string;
    contact_lastname?: string;
    street?: string;
    city?: string;
    state?: string;
    zip?: string;
    country?: string;
    website?: string;
    is_guest?: boolean;
}

export class CustomerController extends ParentController {
    endpoints = {
        getCurrent: "/customer/",

        create: "/customer",
        get: "/customer/:id",
        edit: "/customer/:id",
        delete: "/customer/:id",
        // getInstance: "/customer/:id/instance",
        // getLicense: "/customer/:id/license",
        // getData: "/customer/:id/data"
    };

    async getCurrent(): Promise<CustomerOutput> {
        const { data } = await this.manager.get(this.endpoints.getCurrent);
        return data[0];
    }

    // getInstance(id) {
    //     const url = this._interpolate(this.endpoints.getInstance, { id });
    //     return this.getDataFrom(url);
    // }

    // getLicense(id) {
    //     const url = this._interpolate(this.endpoints.getLicense, { id });
    //     return this.getDataFrom(url);
    // }

    // getData(id) {
    //     const url = this._interpolate(this.endpoints.getData, { id });
    //     return this.getDataFrom(url);
    // }
}
