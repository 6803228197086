import { AxiosPromise } from "axios";
import ParentController from "./ParentController";

interface LoginInput {
    username: string;
    password: string;
}

export class LogController extends ParentController {
    endpoints = {
        login: "/login",
        logout: "/logout",

        otpActivate: "/totp/activate",
        otpBackup: "/totp/backup",
        otpDelete: "/totp",
        otpGetUrl: "/totp",
        otpVerify: "/totp/login"
    };

    // ------------------------------------------------------------------
    // Simple Authentication & log out
    // ------------------------------------------------------------------
    async login(credentials: LoginInput): Promise<AxiosPromise> {
        return this.manager.post(this.endpoints.login, {
            ...credentials
        });
    }

    logout() {
        return this.manager.post(this.endpoints.logout);
    }

    // ------------------------------------------------------------------
    // 2 Factor Authentication
    // ------------------------------------------------------------------
    async otpActivate(token: string): Promise<AxiosPromise> {
        return this.manager.post(this.endpoints.otpActivate, { token });
    }

    async otpVerify(token): Promise<AxiosPromise> {
        return this.manager.post(this.endpoints.otpVerify, { token });
    }

    async otpGetUrl() {
        const { data } = await this.manager.get(this.endpoints.otpGetUrl);

        return data;
    }

    async otpDelete(): Promise<AxiosPromise> {
        return this.manager.delete(this.endpoints.otpDelete);
    }
}
