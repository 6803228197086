import { useMemo } from "react";
import FAIcon from "../../../components/Atoms/Icons/FAIcon";
import { classList } from "../../../helpers/className";
import ButtonCta from "../Header/ButtonCta";
import "./HomeCards.scss";

function HomeCard({
    title,
    children,
    className
}: {
    title?: string;
    children: React.ReactNode;
    className?: string;
}) {
    return (
        <div className={`home-card ${className ?? ""}`}>
            {title && <h2 className="title">{title}</h2>}
            {children}
        </div>
    );
}

function HomeCardCta({
    className,
    title,
    children,
    ctaText,
    ctaLink
}: {
    className?: string;
    title: string;
    children: React.ReactNode;
    ctaText: string;
    ctaLink: string;
}) {
    return (
        <HomeCard title={title} className={`${className ?? ""} card_cta`}>
            {children}
            <ButtonCta
                className="w-full"
                url={ctaLink}
                buttonText={ctaText}
                target={"_blank"}
            />
        </HomeCard>
    );
}

function HomeCardCtaWithIcon({ title, text, links, icon }) {
    const withText = !!text;
    const footerClassname = withText ? "flex-v-gapped" : "community-card__list";

    const content = useMemo(
        () =>
            links.map((link) => (
                <a
                    href={link.link}
                    target="_blank"
                    rel="noreferrer"
                    className="link"
                >
                    {link.text}
                </a>
            )),
        [links]
    );

    return (
        <HomeCard
            className={`${!text ? "community-card-no-text" : "community-card"}`}
        >
            <div className="community-card__header">
                <div className="flex-h-gapped">
                    <FAIcon icon={icon} />
                    <h2 className="title">{title}</h2>
                </div>

                {text ? <p className="paragraph">{text}</p> : null}
            </div>

            <div
                className={classList(
                    "community-card__footer",
                    "h-full",
                    footerClassname,
                    {
                        "flex-v-gapped": withText
                    }
                )}
            >
                {content}
            </div>
        </HomeCard>
    );
}

export { HomeCard, HomeCardCta, HomeCardCtaWithIcon };
