import { useContext, memo } from "react";
import { useLocation } from "react-router-dom";

import NavigationTab from "../../../components/Atoms/Navigation/NavigationTab/NavigationTab";
import NavigationLink from "../../../components/Atoms/Navigation/NavigationLink/NavigationLink";
import GoBackLink from "../../../components/Atoms/Navigation/GoBackLink/GoBackLink";
import NavigationLinkList from "../../../components/Molecules/Dashboard/Navigation/NavigationLinkList";
import { ScopeContext } from "../../../context/ScopeProvider";

import { navigationTabs, navigationLinks } from "./navigationTabs";

import "./Navigation.scss";
interface NavigationProps {
    onLogout: () => void;
}

const Navigation = ({ onLogout }: NavigationProps) => {
    const { user } = useContext(ScopeContext);
    const { pathname } = useLocation();
    const licenseDetailRegex = /\/portal\/licenses\/[\d\w-]+/gm;

    const actions = {
        logout: () => {
            onLogout();
        }
    };

    return (
        <section className="navigation" role="navigation">
            <div className="navigation__content container">
                <ul className="navigation__tab-list">
                    {(licenseDetailRegex.test(pathname) && (
                        <li>
                            <GoBackLink
                                link="/portal/licenses"
                                label="Go Back"
                            />
                        </li>
                    )) ||
                        navigationTabs.map((tab) => {
                            if (!user?.is_manager && tab.id === "users") {
                                return null;
                            }
                            return <NavigationTab key={tab.id} tab={tab} />;
                        })}
                </ul>

                <NavigationLinkList>
                    {navigationLinks.map((link) => {
                        if (!user?.is_manager && link.id === "add-users") {
                            return null;
                        }
                        return (
                            <NavigationLink
                                key={link.id}
                                link={link}
                                action={actions[`${link.action}`]}
                            />
                        );
                    })}
                </NavigationLinkList>
            </div>
        </section>
    );
};

export default memo(Navigation);
