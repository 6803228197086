// -----------------------------------------------------
// dependencies
// -----------------------------------------------------
import { memo } from "react";
import { UserOutput } from "../../../api/UserController";
import ButtonTag from "../../Atoms/Button/ButtonTag";
import Tag from "../../Atoms/Tag/Tag";

// -----------------------------------------------------
// types
// -----------------------------------------------------
interface UserTableRowProps {
    user: UserOutput;
    userIsManager?: boolean;
    onUserActivationClick?: () => void;
    onDeleteUserClick?: () => void;
    connectedUser: UserOutput;
    hasUsers: boolean;
}

// -----------------------------------------------------
// Main component
// -----------------------------------------------------
const UserTableRow = ({
    user,
    userIsManager = false,
    onUserActivationClick,
    onDeleteUserClick,
    connectedUser,
    hasUsers
}: UserTableRowProps): JSX.Element => {
    const { firstname, lastname, email } = user;

    return (
        <tr className="table__row">
            <td className="table__cell">{firstname}</td>
            <td className="table__cell">{lastname}</td>
            <td className="table__cell">{email}</td>
            {userIsManager && (
                <>
                    <td className="table__cell">
                        {(user?.is_verified && (
                            <Tag text="Verified" className="success" />
                        )) || (
                            <ButtonTag onClick={onUserActivationClick}>
                                Resend verification email
                            </ButtonTag>
                        )}
                    </td>

                    {/* <td className="table__cell">
                        <Link to="#" className="table__manage-button">
                            Manage
                        </Link>
                    </td> */}
                </>
            )}
            {hasUsers ? (
                userIsManager && connectedUser.id !== user.id ? (
                    <td>
                        <ButtonTag
                            className="table__cell-delete"
                            onClick={onDeleteUserClick}
                        >
                            Delete
                        </ButtonTag>
                    </td>
                ) : (
                    <td></td>
                )
            ) : null}
        </tr>
    );
};

export default memo(UserTableRow);
