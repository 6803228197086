import { memo } from "react";
import { classList } from "../../../helpers/className";
import IconForbidden from "../Icons/IconForbidden";
import "./ButtonTag.scss";

interface ButtonTagProps {
    onClick?: any;
    children: string;
    className?: string;
    url?: string;
    icon?: string;
    disabled?: boolean;
    disabledText?: string;
    rounded?: boolean;
}

const ButtonTag = ({
    children,
    className = "buttonTag",
    url,
    icon,
    onClick,
    disabled,
    disabledText,
    rounded
}: ButtonTagProps) => {
    const content = (
        <>
            {icon && <img src={icon} className="button-icon" alt="" />}
            {children}
        </>
    );
    if (url) {
        return (
            <a href={url} className={className}>
                {content}
            </a>
        );
    }
    return (
        <button
            className={classList(className, { rounded, disabled })}
            onClick={onClick}
            disabled={disabled}
        >
            {(!disabled && content) || disabledText || content}
            {disabled && <IconForbidden />}
        </button>
    );
};

export default memo(ButtonTag);
