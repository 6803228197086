import { memo } from "react";

import "./LicenseCard.scss";

const LicenseCard = ({ type, date }) => (
    <>
        <dt className="license-type">{type}</dt>
        <dd className="license-date">{date}</dd>
    </>
);

export default memo(LicenseCard);
