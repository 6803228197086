import { ReactNode, memo } from "react";
import { classList } from "../../../../helpers/className";

import "./FormControl.scss";

interface FormControlProps {
    children: ReactNode;
    withIcon?: boolean;
    className?: string;
}

const FormControl = ({
    children,
    withIcon = false,
    className
}: FormControlProps): JSX.Element => {
    const baseClassName = withIcon ? "formControl--withIcon" : "formControl";

    return (
        <div className={classList(baseClassName, className)}>{children}</div>
    );
};

export default memo(FormControl);
