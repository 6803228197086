import { ReactNode, memo } from "react";
import "./Tooltip.scss";

interface TooltipProps {
    children: ReactNode | string;
    content: string;
}

const Tooltip = ({ children, content }: TooltipProps): JSX.Element => (
    <span className="tooltip__container">
        {children}
        <div className="tooltip__content">{content}</div>
    </span>
);

export default memo(Tooltip);
