import { AxiosInstance } from "axios";

export default abstract class ParentController {
    manager: AxiosInstance;
    regexTemplate = /:([a-zA-Z]+)((?=\/|\?|&)*)/g;

    public endpoints: Record<string, string> = {
        create: "",
        get: "",
        edit: "",
        delete: ""
    };

    constructor(manager) {
        this.manager = manager;
    }

    // helper
    _interpolate(str: string, templateContext: Record<string, string>): string {
        const result = str
            .replace(this.regexTemplate, (match: string, submatch: string) => {
                return templateContext[submatch] || "";
            })
            .replace(/[^=&|?]+=(&|$)/g, "")
            .replace(/&$/, "");

        return result;
    }

    // GET METHODS
    get<O>(id: string): Promise<O> {
        const url = this._interpolate(this.endpoints.get, { id });

        return this.manager.get(url).then((res) => res.data);
    }

    // POST METHODS
    create(values: any) {
        return this.manager.post(this.endpoints.create, { ...values });
    }

    edit(id: string, values: any) {
        const url = this._interpolate(this.endpoints.edit, { id });

        return this.manager.patch(url, { ...values });
    }

    delete(id: string) {
        const url = this._interpolate(this.endpoints.delete, { id });

        return this.manager.delete(url);
    }

    getDataFrom(url) {
        return this.manager.get(url).then((res) => res.data);
    }

    postData(url) {
        return this.manager.post(url);
    }
}
