import {
    iconLicense,
    iconUsers,
    iconSettings,
    iconHome,
    iconLogout
} from "../../../images/pictograms";

export type navigationTabType = {
    id: string;
    label: string;
    img: string;
};

export const navigationTabs: navigationTabType[] = [
    {
        id: "home",
        label: "Home",
        img: iconHome
    },
    {
        id: "licenses",
        label: "Licenses",
        img: iconLicense
    },
    {
        id: "users",
        label: "Users",
        img: iconUsers
    },
    {
        id: "settings",
        label: "Settings",
        img: iconSettings
    }
];

export type navigationLinkType = {
    id: string;
    label: string;
    icon: string;
    action?: string;
};

export const navigationLinks: navigationLinkType[] = [
    {
        id: "log-out",
        label: "Log out",
        icon: iconLogout,
        action: "logout"
    }
];
