import { MouseEventHandler, memo } from "react";
import { iconCrossWhite } from "../../../images/pictograms";
import FAIcon from "../Icons/FAIcon";

interface ButtonCloseProps {
    onClick: MouseEventHandler<HTMLButtonElement>;
}

const ButtonClose = ({ onClick }: ButtonCloseProps): JSX.Element => (
    <button className="drawer__close" onClick={onClick}>
        {/* <img src={iconCrossWhite} alt="" /> */}
        <FAIcon icon={iconCrossWhite} />
    </button>
);

export default memo(ButtonClose);
