import { URLS } from "../../../../api/urls";
import ButtonCta from "../../Header/ButtonCta";

import "./LicenseNotification.scss";

const LicenseNotification = ({
    licenseTitle,
    expiryDate
}: {
    licenseTitle: string;
    expiryDate: string;
}) => (
    <div className="LicenseNotification">
        <span className="LicenseNotification__Content">
            Your license {licenseTitle} is about to expire on the: {expiryDate}
        </span>

        <ButtonCta
            url={URLS.website.contactPage}
            buttonText="Renew"
            className="LicenseNotification__Cta"
            target="_blank"
        />
    </div>
);

export default LicenseNotification;
