import { useQuery } from "react-query";

import LicenseTable from "../../content/Dashboard/LicenseTable/LicenseTable";

import { CLIENT } from "../../api/client";
import { DrawerProvider } from "../../context/DrawerContext";

const LicenseListPage = () => {
    const {
        data: licenses = [],
        status,
        isFetching,
        isLoading,
        refetch,
        error: fetchLicensesError
    } = useQuery({
        queryFn: () => CLIENT.LicenseController.getAll(),
        queryKey: ["get-all-licenses"],
        refetchOnMount: true
    });

    const props = {
        licenses,
        status,
        isFetching,
        isLoading,
        refetch,
        fetchLicensesError
    };

    return (
        <DrawerProvider>
            {(paramsCommunity) => (
                <DrawerProvider>
                    {(paramsTrial) => (
                        <LicenseTable
                            {...props}
                            isTrialDrawerOpen={paramsTrial?.isOpen}
                            setIsTrialDrawerOpen={paramsTrial?.setIsOpen}
                            isCommunityDrawerOpen={paramsCommunity?.isOpen}
                            setIsCommunityDrawerOpen={
                                paramsCommunity?.setIsOpen
                            }
                        />
                    )}
                </DrawerProvider>
            )}
        </DrawerProvider>
    );
};

export default LicenseListPage;
