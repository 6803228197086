const BASE_URLS = {
    strangebee: "https://www.strangebee.com"
};

export const URLS = {
    website: {
        contactPage: `${BASE_URLS.strangebee}/contact`,
        howTo: "https://docs.strangebee.com",
        apiDocumentation: "https://docs.strangebee.com/thehive/api-docs/",
        faq: `${BASE_URLS.strangebee}/thehive/#faq`,
        theHive: {
            overview: `${BASE_URLS.strangebee}/thehive`
        }
    },
    marketing: {
        buyLicense: "https://www.strangebee.com/contact"
    },
    blog: {
        theHiveArticle:
            "https://blog.strangebee.com/thehive-5-0-is-now-available/"
    },
    community: {
        feature_request:
            "https://strangebee.atlassian.net/servicedesk/customer/portal/4/group/5/create/31",
        slack: "https://strangebee-community.slack.com/",
        blog: "https://blog.strangebee.com",
        socials: {
            X: "https://twitter.com/StrangeBee",
            linkedIn: "https://www.linkedin.com/company/strangebee",
            mastodon: "https://infosec.exchange/@StrangeBee"
        }
    },
    licenses: {
        pricing: `${BASE_URLS.strangebee}/pricing/`,
        faq: `${BASE_URLS.strangebee}/pricing/#faq`
    },
    support: {
        theHiveDoc: "https://docs.strangebee.com",
        apiDoc: "https://docs.strangebee.com/thehive/api-docs/",
        releaseNotes:
            "https://docs.strangebee.com/thehive/release-notes/latest",
        technicalSupport:
            "https://strangebee.atlassian.net/servicedesk/customer/portal/4"
    }
};
