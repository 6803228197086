import React, { createContext, ReactNode, useState } from "react";
import { LicenseOutput } from "../api/LicenseController";
import { UserOutput } from "../api/UserController";

interface ScopeProviderOutput {
    user: UserOutput | undefined;
    setUser: React.Dispatch<React.SetStateAction<UserOutput>>;
    licenses?: LicenseOutput[];
    setLicenses: React.Dispatch<React.SetStateAction<LicenseOutput[]>>;
    isPremiumUser: boolean;
    expiringLicense?: LicenseOutput;
}

export const ScopeContext = createContext<ScopeProviderOutput>(null);

interface ScopeProviderProps {
    children: ReactNode;
}

export const ScopeProvider = ({ children }: ScopeProviderProps) => {
    const [user, setUser] = useState<UserOutput>();
    const [licenses, setLicenses] = useState<LicenseOutput[]>();
    const isPremiumUser = licenses?.some(
        (license) =>
            (license.plan === "Platinum" || license.plan === "Gold") &&
            !license.locked &&
            license.kind === "Regular" &&
            new Date(license.expires_at).getTime() > new Date().getTime()
    );

    const expiringLicense = licenses?.filter(
        (license) =>
            new Date(license.expires_at).getTime() - new Date().getTime() <
                30 * 24 * 60 * 60 * 1000 &&
            new Date(license.expires_at).getTime() > new Date().getTime()
    )[0];

    return (
        <ScopeContext.Provider
            value={{
                user,
                setUser,
                licenses,
                setLicenses,
                isPremiumUser,
                expiringLicense
            }}
        >
            {children}
        </ScopeContext.Provider>
    );
};
