import { memo, ReactNode } from "react";

interface QuotaProps {
    label: string | ReactNode;
    value: string | number | ReactNode;
}

const Quota = ({ label, value }: QuotaProps): JSX.Element => (
    <span className="quota">
        {label} {value}
    </span>
);

export default memo(Quota);
