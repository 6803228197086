import LoginContentSection from "../../components/Layouts/LoginContentSection/LoginContentSection";
import LoginPageLayout from "../../components/Layouts/LoginPageLayout/LoginPageLayout";
import LoginForm from "../../components/Molecules/LoginForm/LoginForm";

interface LoginPageContentProps {
    error?: string;
    onFormSubmit: Function;
    isLoading: boolean;
}

const LoginContent = ({
    error,
    onFormSubmit,
    isLoading
}: LoginPageContentProps): JSX.Element => (
    <LoginPageLayout className="loginPage">
        <LoginContentSection
            title="License Portal"
            subTitle="Please log in using your credentials"
            error={error}
        >
            <LoginForm onFormSubmit={onFormSubmit} isLoading={isLoading} />
        </LoginContentSection>
    </LoginPageLayout>
);

export default LoginContent;
