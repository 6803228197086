import { memo } from "react";

interface DrawerTitleProps {
    title: string;
}

const DrawerTitle = ({ title }: DrawerTitleProps): JSX.Element => (
    <p className="drawer__title" dangerouslySetInnerHTML={{ __html: title }} />
);

export default memo(DrawerTitle);
