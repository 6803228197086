import { ReactNode, memo } from "react";
import Alert from "../../Molecules/Alert/Alert";
import "./LoginContentSection.scss";

interface LoginContentSectionProps {
    title?: string;
    subTitle?: string;
    error?: string;
    children: ReactNode;
}

const LoginContentSection = ({
    title,
    subTitle,
    children,
    error
}: LoginContentSectionProps): JSX.Element => (
    <div className="login__content-section">
        {title && (
            <h2
                className="title-1"
                dangerouslySetInnerHTML={{ __html: title }}
            />
        )}
        {subTitle && (
            <p
                className="text-large"
                dangerouslySetInnerHTML={{ __html: subTitle }}
            />
        )}
        {error && <Alert content={error} className={["danger", "center"]} />}
        {children}
    </div>
);

export default memo(LoginContentSection);
