import LicenseCard from "./LicenseCard";
import "./LicenseManager.scss";
import { HomeCard, HomeCardCta } from "../HomeCards";
import { NavLink } from "react-router-dom";
import LinkCard from "../LinkCard";
import DrawerCommunityLicense from "../../Drawer/DrawerCommunityLicense";
import { useState } from "react";
import { LicenseOutput } from "../../../../api/LicenseController";
import { URLS } from "../../../../api/urls";

const LicenseManager = ({ licenses }: { licenses: LicenseOutput[] }) => {
    const [isCommunityDrawerOpen, setIsCommunityDrawerOpen] = useState(false);
    const onRequestCommunity = () => setIsCommunityDrawerOpen(true);

    return (
        <>
            <div className="flex-col">
                <h2 className="home-title">Licenses ({licenses.length})</h2>
                <div className="grid">
                    <HomeCard className="align-start space-between">
                        <div className="w-full flex-v-gapped flex-col">
                            <dl className="license-card">
                                {licenses
                                    .sort(
                                        (a, b) =>
                                            new Date(a.expires_at).getTime() -
                                            new Date(b.expires_at).getTime()
                                    )
                                    .slice(0, 3)
                                    .map((license, index) => (
                                        <LicenseCard
                                            key={index}
                                            type={
                                                license.description ||
                                                license.plan
                                            }
                                            date={license.expires_at}
                                        />
                                    ))}
                            </dl>
                        </div>
                        <div className="w-full">
                            <hr className="separator" />
                            <NavLink to="/portal/licenses" className="link">
                                Manage
                            </NavLink>
                        </div>
                    </HomeCard>
                    <HomeCardCta
                        className="align-start space-between"
                        title={"Purchase a new license"}
                        ctaText="Contact sales"
                        ctaLink={URLS.website.contactPage}
                    >
                        <p className="paragraph">
                            You are interested in purchasing a TheHive license
                            or you have a specific request? Get in touch with
                            our commercial team.
                        </p>
                    </HomeCardCta>
                    <div className="flex-v-gapped">
                        <LinkCard
                            text="Compare versions"
                            link={URLS.licenses.pricing}
                            className="align-start"
                        />
                        <LinkCard
                            text="Pricing FAQ"
                            link={URLS.licenses.faq}
                            className="align-start"
                        />
                        <LinkCard
                            text="Request a Community License"
                            className="align-start"
                            onClick={onRequestCommunity}
                        />
                    </div>
                </div>
            </div>
            <DrawerCommunityLicense
                isOpen={isCommunityDrawerOpen}
                setIsOpen={setIsCommunityDrawerOpen}
            />
        </>
    );
};

export default LicenseManager;
