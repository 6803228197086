import { useState, memo } from "react";
import { useMutation } from "react-query";
import { useParams, Link } from "react-router-dom";
import { AxiosError } from "axios";

import AccountActivationContent from "../../content/Login/AccountActivationContent";
import SuccessContent from "../../content/Login/SuccessContent";

import { AccountActivationFormValuesTypes } from "../../components/Molecules/LoginForm/AccountActivationForm";
import { CLIENT } from "../../api/client";
import { MSG } from "../../data/msg";

import "./SuccessPage";
import "./LoginPage.scss";

const PasswordConfirmationPage = (): JSX.Element => {
    const [errMsg, setErrMsg] = useState<string | null>();
    const { id, token } = useParams();

    const { mutate, error, isLoading, isSuccess, reset } = useMutation<
        any,
        Error,
        AccountActivationFormValuesTypes
    >(
        (values: AccountActivationFormValuesTypes) =>
            CLIENT.PasswordController.activateAccount(
                id,
                values.password,
                token
            ),
        {
            onError: (err: AxiosError) => {
                if (err?.response.status === 500) {
                    setErrMsg(MSG.err500);
                }

                if (err?.response.status === 400) {
                    setErrMsg("Unable to activate this account");
                }
            }
        }
    );

    const onFormSubmit = (values: AccountActivationFormValuesTypes) => {
        reset();
        setErrMsg(null);
        mutate(values);
    };

    return (
        (!isSuccess && (
            <AccountActivationContent
                error={errMsg ? errMsg : error?.message}
                isLoading={isLoading}
                onFormSubmit={onFormSubmit}
            />
        )) || (
            <SuccessContent
                title="Your account has been activated !"
                subtitle="You can now connect to your account"
                bottomContent={
                    <Link to="/login" className="formLink">
                        Log in
                    </Link>
                }
            />
        )
    );
};

export default memo(PasswordConfirmationPage);
