// * For next version
// import TableCheckbox from "../../Atoms/Table/TableCheckbox";
// * END For next version

import { memo } from "react";

interface UserTableHeaderProps {
    userIsManager: boolean;
    hasUsers: boolean;
}

const UserTableHeader = ({
    userIsManager = false,
    hasUsers = false
}: UserTableHeaderProps): JSX.Element => (
    <thead className="table__head">
        <tr className="table__headRow">
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            {userIsManager && <th>User status</th>}
            {hasUsers && <th></th>}
        </tr>
    </thead>
);

export default memo(UserTableHeader);
