import axios, { AxiosInstance } from "axios";
import { PasswordController } from "./PasswordController";
import { LicenseController } from "./LicenseController";
import { InstanceController } from "./InstanceController";
import { CustomerController } from "./CustomerController";
import { CollectedDataController } from "./CollectedDataController";
import { LogController } from "./LogController";
import { UserController } from "./UserController";

class Client {
    manager: AxiosInstance;

    constructor(baseUrl) {
        this.manager = axios.create({
            baseURL: baseUrl,
            xsrfCookieName: "csrftoken",
            xsrfHeaderName: "X-CSRFToken",
            withCredentials: true
        });
    }

    get CollectedDataController() {
        return new CollectedDataController(this.manager);
    }

    get CustomerController() {
        return new CustomerController(this.manager);
    }

    get InstanceController() {
        return new InstanceController(this.manager);
    }

    get LicenseController() {
        return new LicenseController(this.manager);
    }

    get LogController() {
        return new LogController(this.manager);
    }

    get PasswordController() {
        return new PasswordController(this.manager);
    }

    get UserController() {
        return new UserController(this.manager);
    }
}

const BASE_URL = process.env.NODE_ENV === "production" ? "/api" : "/api";

export const CLIENT = new Client(BASE_URL);
