import { ReactElement } from "react-markdown/lib/react-markdown";
import "./QRCodeContainer.scss";

interface QRCodeContainerProps {
    children: ReactElement | ReactElement[];
}

const QRCodeContainer = ({ children }: QRCodeContainerProps): JSX.Element => (
    <div className="QRCode__container">{children}</div>
);

export default QRCodeContainer;
