import ParentController from "./ParentController";

export interface UserInput {
    firstname: string;
    lastname: string;
    email: string;
    title?: string | null;
    company_name?: string;
    country?: string;
    street?: string;
    city?: string;
    state?: string;
    zip?: string;
    website?: string;
    // customer?: string;
    // locked?: boolean;
}

export interface UserOutput {
    id: string;
    is_manager: boolean;
    is_verified: boolean;
    firstname: string;
    lastname: string;
    email: string;
    title?: string | null;
    street?: string;
    city?: string;
    state?: string;
    zip?: string;
    customer: string;
    locked?: boolean;
    last_login: string;
    is_guest?: boolean;
    totp?: boolean;
}

export interface UserSimpleOutput {
    data: string;
}

export class UserController extends ParentController {
    endpoints = {
        getCurrent: "/user/current/",
        create: "/user/",
        delete: "/user/:id",
        create_registration_request: "/user/register",
        getAll: "/user/",
        reactivate: "/user/:id/reactivate/",
        verify_email: "user/verify_email/"
        // edit: "/user/:id",
        // delete: "/user/:id",
    };

    async getCurrent() {
        const url = this.endpoints.getCurrent;
        const { data } = await this.manager.get(url);
        
        return data;
    }

    async delete(userId: string): Promise<any> {
        const url = this._interpolate(this.endpoints.delete, { id: userId });
        return this.manager.delete(url);
    }

    // async getOne(userId: string): Promise<UserOutput> {
    //     const url = this._interpolate(this.endpoints.getOne, { id: userId });
    //     const { data } = await this.manager.get<UserOutput[]>(url);

    //     return data[0];
    // }

    async reactivate(userId: string): Promise<UserSimpleOutput> {
        const url = this._interpolate(this.endpoints.reactivate, {
            id: userId
        });
        const { data } = await this.manager.get<UserSimpleOutput>(url);

        return data;
    }

    async create(user: UserInput): Promise<any> {
        return this.manager.post(this.endpoints.create, user);
    }

    async create_registration_request(user: UserInput): Promise<any> {
        return this.manager.post(
            this.endpoints.create_registration_request,
            user
        );
    }

    async verify_email(token: string) {
        return this.manager.get(this.endpoints.verify_email + token);
    }

    async getAll(): Promise<UserOutput[]> {
        const { data } = await this.manager.get<UserOutput[]>(
            this.endpoints.getAll
        );

        return data;
    }
}
