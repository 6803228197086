// -----------------------------------------------------
// dependencies
// -----------------------------------------------------
import { useForm } from "react-hook-form";
import { memo } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import Button from "../../../Atoms/Button/Button";
import FormInput from "../../../Atoms/Form/Input/Input";
import { classList } from "../../../../helpers/className";

// -----------------------------------------------------
// typescript rules
// -----------------------------------------------------
interface LicenseFormProps {
    onSubmitForm: (values: LicenseFormValuesType) => Promise<any>;
    onDelete?: Function;
}

export type LicenseFormValuesType = {
    keyActivation: string;
};

// -----------------------------------------------------
// form validation
// -----------------------------------------------------
const formSchema = yup.object().shape({
    keyActivation: yup
        .string()
        .required("Activation key is required to activate your license")
});

// -----------------------------------------------------
// component
// -----------------------------------------------------
const LicenseForm = ({ onSubmitForm }: LicenseFormProps): JSX.Element => {
    const {
        register,
        handleSubmit,
        reset,
        watch,
        formState: { errors }
    } = useForm<LicenseFormValuesType>({ resolver: yupResolver(formSchema) });

    const handleFormSubmit = (values: LicenseFormValuesType): void => {
        onSubmitForm(values)
            .then(reset)
            .catch(() => {});
    };

    const { keyActivation } = watch();

    return (
        <div className="licenseDetail__formSection">
            {/* <div className="licenseDetail__formHeader">
                <p className="licenseDetail__formHeaderInfo">
                    License key activation challenge
                </p>
            </div> */}

            <form
                autoComplete="off"
                className="licenseDetail__form"
                onSubmit={handleSubmit((data) => handleFormSubmit(data))}
            >
                <FormInput
                    name="keyActivation"
                    placeholder="Please paste the license challenge from your TheHive instance to activate your license !"
                    className={classList({ error: !!errors.keyActivation })}
                    type="textarea"
                    register={register}
                    error={errors.keyActivation?.message}
                />
                <div className="button-section">
                    <Button
                        className={classList("button--primary")}
                        disabled={!keyActivation}
                        rounded
                    >
                        Activate License
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default memo(LicenseForm);
