// -----------------------------------------------------
// dependencies
// -----------------------------------------------------
import { memo } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import useDisabledButton from "../../../hooks/useDisabledButton";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import YupPassword from "yup-password";

import FormInput from "../../Atoms/Form/Input/Input";
import Button from "../../Atoms/Button/Button";
import LoadingScreen from "../../Layouts/LoadingScreen/LoadingScreen";

import { iconUser, iconLock } from "../../../images/pictograms";

import "./LoginForm.scss";
import { classList } from "../../../helpers/className";
import { URLS } from "../../../api/urls";

YupPassword(yup);

// -----------------------------------------------------
// typescript rules
// -----------------------------------------------------
interface LoginFormProps {
    onFormSubmit: Function;
    isLoading?: boolean;
}

export type LoginFormValuesType = {
    username: string;
    password: string;
    remember?: string;
};

// -----------------------------------------------------
// form validation
// -----------------------------------------------------
const formSchema = yup.object().shape({
    username: yup
        .string()
        .email("Username must be a valid email address")
        .lowercase("Your email adress should be to lower case")
        .required("Username is required"),
    password: yup.string().required("Password is required"),
    remember: yup.boolean()
});

// -----------------------------------------------------
// component
// -----------------------------------------------------
const LoginForm = ({
    onFormSubmit,
    isLoading
}: LoginFormProps): JSX.Element => {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors }
    } = useForm<LoginFormValuesType>({ resolver: yupResolver(formSchema) });

    const { username, password } = watch();
    const { isButtonDisabled } = useDisabledButton([username, password], true);

    const handleFormSubmit = (values: LoginFormValuesType) => {
        onFormSubmit(values);
    };

    return (
        <form
            autoComplete="off"
            className={classList("loginForm")}
            onSubmit={handleSubmit((data) => handleFormSubmit(data))}
        >
            <FormInput
                name="username"
                icon={iconUser}
                placeholder="Username"
                className={classList({ error: !!errors.username })}
                register={register}
                error={errors.username?.message}
            />
            <FormInput
                type="password"
                name="password"
                icon={iconLock}
                placeholder="Password"
                className={classList({ error: !!errors.password })}
                register={register}
                error={errors.password?.message}
            />
            <Button
                className="button--primary button--block"
                disabled={isButtonDisabled}
            >
                {isLoading ? <LoadingScreen contained /> : "Log in"}
            </Button>

            <div className="loginForm__row">
                <span>
                    <span className="registerText">No account? </span>
                    <Link to="/account/register" className="formLink">
                        Register here
                    </Link>
                </span>

                <Link to="/account/forgot_password" className="formLink">
                    I forgot my password
                </Link>
            </div>
        </form>
    );
};

export default memo(LoginForm);
