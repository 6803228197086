import { memo } from "react";

interface SupportCtaProps {
    url: string;
    text?: string;
    buttonText: string;
    className?: string;
    [key: string]: unknown;
}

const ButtonCta = ({
    url,
    text,
    buttonText,
    className,
    ...props
}: SupportCtaProps): JSX.Element => (
    <div className={`support__cta ${className ?? ""}`}>
        <a href={url} className="button--primary button--block" {...props}>
            {buttonText}
        </a>
        {text && (
            <p
                className="home__cta-info"
                dangerouslySetInnerHTML={{ __html: text }}
            />
        )}
    </div>
);

export default memo(ButtonCta);
