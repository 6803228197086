// -----------------------------------------------------
// dependencies
// -----------------------------------------------------
import { memo, useState } from "react";
import _ from "lodash";

import Alert from "../../../components/Molecules/Alert/Alert";
import Button from "../../../components/Atoms/Button/Button";
import Tag from "../../../components/Atoms/Tag/Tag";
import DrawerOTP from "../Drawer/DrawerOTP";
import FormControl from "../../../components/Atoms/Form/FormControl/FormControl";
import LoadingScreen from "../../../components/Layouts/LoadingScreen/LoadingScreen";
import SettingCard from "../../../components/Layouts/Setting/SettingCard";
import SettingCardBody from "../../../components/Layouts/Setting/SettingCardBody";
import SettingTable from "../../../components/Layouts/Table/SettingTable";
import SimpleTextInput from "../../../components/Atoms/Form/Input/SimpleTextInput";

import { renderTableRows } from "../../../components/Layouts/Table/SettingTableRow";

import { UserOutput } from "../../../api/UserController";
import { CustomerOutput } from "../../../api/CustomerController";

import { classList } from "../../../helpers/className";
import "./Settings.scss";

// -----------------------------------------------------
// Main component
// -----------------------------------------------------
interface SettingsProps {
    status: "error" | "loading" | "idle" | "success";
    isFetching: boolean;
    isLoading: boolean;
    customer: CustomerOutput;
    error: any;
    success?: string;
    user: UserOutput;
    isOpen: boolean;
    setIsOpen: Function;
    onDeleteOTP?: Function;
}

const Settings = ({
    status,
    isFetching,
    isLoading,
    customer,
    error,
    success,
    user,
    isOpen,
    setIsOpen,
    onDeleteOTP
}: SettingsProps): JSX.Element => {
    const [inputText, setInputText] = useState<string>("");
    const securityWord = user.email;

    const [showDeleteOTPSection, setShowDeleteOTPSection] =
        useState<boolean>(false);

    const openDrawer = () => setIsOpen(true);

    const handleInputChange = (e) => {
        setInputText(e.target.value);
    };

    if (status === "loading" || isFetching || isLoading) {
        return <LoadingScreen />;
    }

    const handleDeleteOTP = () => {
        onDeleteOTP();
        setShowDeleteOTPSection(false);
        setInputText("");
    };

    return (
        (error && (
            <Alert
                className={["danger", "center"]}
                content="Unable to get your company informations"
            />
        )) || (
            <>
                <section className="setting">
                    {success && (
                        <Alert className="primary" content={success} closable />
                    )}
                    <SettingCard title="My company">
                        <SettingTable>
                            {renderTableRows(
                                _.omit(
                                    customer,
                                    "id",
                                    "is_guest",
                                    "max_community_licenses",
                                    "max_trial_licenses",
                                    "category"
                                ),
                                {
                                    contact_firstname: "Contact First Name",
                                    contact_lastname: "Contact Last Name",
                                    contact_email: "Contact Email"
                                }
                            )}
                        </SettingTable>
                    </SettingCard>

                    <SettingCard title="My information">
                        <SettingTable>
                            <SettingTable>
                                {renderTableRows(
                                    _.omit(
                                        user,
                                        "id",
                                        "is_verified",
                                        "is_manager",
                                        "customer",
                                        "last_login",
                                        "totp"
                                    ),
                                    {
                                        firstname: "First Name",
                                        lastname: "Last Name"
                                    }
                                )}
                            </SettingTable>
                        </SettingTable>
                    </SettingCard>

                    <SettingCard
                        title={"Two-Factor Authentication "}
                        tag={
                            user.totp ? (
                                <Tag text="Enabled" className="success" />
                            ) : null
                        }
                    >
                        {user.totp ? (
                            <>
                                <Button
                                    onClick={() =>
                                        setShowDeleteOTPSection(true)
                                    }
                                    className={["navigation__link", "danger"]}
                                >
                                    Disable secured authentication
                                </Button>

                                {showDeleteOTPSection && (
                                    <SettingCardBody>
                                        <p className="danger">
                                            Before you can disable the
                                            Two-Factor Authentication, please
                                            enter the security word "
                                            <em>{securityWord}</em>" in the
                                            input below.
                                        </p>
                                        <FormControl className="error">
                                            <SimpleTextInput
                                                onChange={handleInputChange}
                                                placeholder="Enter the security word"
                                                value={inputText}
                                                className={[
                                                    "error",
                                                    "small",
                                                    "bold"
                                                ]}
                                            />
                                        </FormControl>
                                        <Button
                                            className={classList(
                                                "button--danger",
                                                {
                                                    disabled:
                                                        inputText !==
                                                        securityWord
                                                }
                                            )}
                                            onClick={handleDeleteOTP}
                                        >
                                            Disable
                                        </Button>
                                    </SettingCardBody>
                                )}
                            </>
                        ) : (
                            <Button
                                onClick={openDrawer}
                                className="navigation__link"
                            >
                                Activate secured authentication
                            </Button>
                        )}
                    </SettingCard>
                </section>

                <DrawerOTP isOpen={isOpen} setIsOpen={setIsOpen} />
            </>
        )
    );
};

export default memo(Settings);
