export function parseDate(date) {
    const tempDate = new Date(date);
    return tempDate.toLocaleDateString();
}

export function getRemainingDaysFromDate(date): number {
    const oneDay = 1000 * 60 * 60 * 24;
    const presentDate = new Date();
    const targetDate = new Date(date);

    return Math.round((targetDate.getTime() - presentDate.getTime()) / oneDay);
}

export function renderStringDate(date): string {
    const parsedDate = new Date(date);
    const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric"
    };
    // @ts-ignore
    return parsedDate.toLocaleDateString("en-US", options);
}
