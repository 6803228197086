import ParentController from "./ParentController";

export class PasswordController extends ParentController {
    endpoints = {
        login: "/login",
        forgotPassword: "/password_reset/",
        confirmPassword: "/password_reset/confirm/",

        activateAccount: "/account/activate/:id",
        
        reset: "/accounts/password_reset/:id/:token",
        setPassword: "/accounts/password_reset/:id/set-password"
    };

    login() {
        return this.manager.get(this.endpoints.login);
    }

    /* Call the API to reset the password for a given email */
    forgotPassword(email: string) {
        return this.manager.post(this.endpoints.forgotPassword, { email });
    }

    /* Set the new password after a password */
    confirmPassword(password: string, token: string) {
        const url = this.endpoints.confirmPassword;
        return this.manager.post(url, {password, token});
    }

    activateAccount(id: string, password: string, token: string) {
        const url = this._interpolate(this.endpoints.activateAccount, {id});
        return this.manager.post(url, {password, token});
    }

    reset(id: string, token: string) {
        const url = this._interpolate(this.endpoints.reset, { id, token });
        return this.postData(url);
    }

    setPassword(id: string, data: any) {
        const url = this._interpolate(this.endpoints.setPassword, { id });
        return this.manager.post(url, data);
    }

}
