import { memo, MouseEventHandler } from "react";
import { classList } from "../../../helpers/className";
import FAIcon from "../Icons/FAIcon";

interface ButtonLinkProps {
    title: string;
    icon?: string;
    onClick: MouseEventHandler<HTMLButtonElement>;
    className?: string | string[];
}

const ButtonLink = ({
    title,
    icon,
    onClick,
    className
}: ButtonLinkProps): JSX.Element => (
    <button
        className={classList("navigation__link", className)}
        onClick={onClick}
    >
        {/* {icon && <img className="navigation__linkIcon" src={icon} alt="" />} */}
        {icon && <FAIcon icon={icon} />}
        <span
            className="navigation__linkLabel"
            dangerouslySetInnerHTML={{ __html: title }}
        />
    </button>
);

export default memo(ButtonLink);
