import { ReactNode, memo } from "react";
import { classList } from "../../../helpers/className";

import "./LoginContainer.scss";

interface LoginContainerProps {
    children: ReactNode;
    className?: string;
}

const LoginContainer = ({
    children,
    className = ""
}: LoginContainerProps): JSX.Element => (
    <section className={classList("login__container", className)}>
        {children}
    </section>
);

export default memo(LoginContainer);
