import { memo } from "react";
import { classList } from "../../../helpers/className";

interface FAIconProps {
    icon: string | string[];
    className?: string | string[];
}

const FAIcon = ({ icon, className }: FAIconProps): JSX.Element => (
    <i className={classList(icon, className)} />
);

export default memo(FAIcon);
