// -----------------------------------------------------
// dependencies
// -----------------------------------------------------
// * libraries
import { useContext } from "react";
import { Navigate, Outlet, Route, Routes, useNavigate } from "react-router";
import { useMutation } from "react-query";

// * navigation components
import Header from "../../content/Dashboard/Header/Header";
import Navigation from "../../content/Dashboard/Navigation/Navigation";

// * pages
// import Invoices from "../../content/Dashboard/Invoices/Invoices";
import HomePage from "./HomePage";
import LicenseListPage from "./LicenseListPage";
import LicensePage from "./LicensePage";
import SettingPage from "./SettingsPage";
import UserPage from "./UserPage";

//  * contexts
import { ScopeContext } from "../../context/ScopeProvider";

// * helpers
import { CLIENT } from "../../api/client";
import { queryClient } from "../..";

import "./Dashboard.scss";
import Aside from "../../content/Dashboard/Aside/Aside";
import AsideRenew from "../../content/Dashboard/Aside/AsideRenew";
import AsideDetail from "../../content/Dashboard/Aside/AsideDetail";

// -----------------------------------------------------
// template component
// -----------------------------------------------------
interface LayoutProps {
    onLogout: () => void;
}

// -----------------------------------------------------
// helper component
// -----------------------------------------------------
const Layout = ({ onLogout }: LayoutProps) => (
    <div className="dashboard">
        <Header />
        <Navigation onLogout={onLogout} />

        <div className="board">
            <div className="board__content--full container">
                <section className="board__main">
                    <Outlet />
                </section>
            </div>
        </div>
    </div>
);

// -----------------------------------------------------
// Main component
// -----------------------------------------------------
const Dashboard = () => {
    const navigate = useNavigate();
    const { setUser } = useContext(ScopeContext);

    const { mutate } = useMutation(() => CLIENT.LogController.logout());

    const forceLogout = () => {
        queryClient.clear();
        setUser(null);
        navigate("/login");
    };

    const onLogout = () => {
        mutate(null, {
            onSuccess: () => forceLogout(),
            onError: (err: any) => {
                if (err.response.status === 403) {
                    forceLogout();
                }
            }
        });
    };

    return (
        <Routes>
            <Route path="" element={<Layout onLogout={onLogout} />}>
                <Route index element={<Navigate to="home" />} />

                <Route path="home" element={<HomePage />} />
                <Route path="licenses" element={<LicenseListPage />} />
                <Route path="licenses/:licenseId" element={<LicensePage />} />
                <Route path="users" element={<UserPage />} />
                <Route path="settings" element={<SettingPage />} />

                <Route path="*" element={<Navigate to="home" />} />
            </Route>
        </Routes>
    );
};

export default Dashboard;
