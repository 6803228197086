import { memo, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { AxiosError } from "axios";

import SetPasswordContent from "../../content/Login/SetPasswordContent";
import SuccessContent from "../../content/Login/SuccessContent";

import { AccountActivationFormValuesTypes } from "../../components/Molecules/LoginForm/AccountActivationForm";
import { CLIENT } from "../../api/client";
import { useMutation } from "react-query";
import { MSG } from "../../data/msg";

import "./LoginPage.scss";
import "./SuccessPage";

const SetPasswordPage = (): JSX.Element => {
    const [errMsg, setErrMsg] = useState<string | null>();
    const { token } = useParams();
    const { mutate, error, isLoading, isSuccess, reset } = useMutation<
        any,
        Error,
        AccountActivationFormValuesTypes
    >(
        (values) =>
            CLIENT.PasswordController.confirmPassword(values.password, token),
        {
            onError: (err: AxiosError) => {
                if (err?.response.status === 500) {
                    setErrMsg(MSG.err500);
                }
                if (
                    err?.response.status === 400 ||
                    err?.response.status === 404
                ) {
                    setErrMsg("Unable to set password for this account");
                }
            }
        }
    );

    const onFormSubmit = (values: AccountActivationFormValuesTypes) => {
        reset();
        setErrMsg(null);
        mutate(values);
    };

    return (
        (!isSuccess && (
            <SetPasswordContent
                error={errMsg ? errMsg : error?.message}
                isLoading={isLoading}
                onFormSubmit={onFormSubmit}
            />
        )) || (
            <SuccessContent
                title="Your password has been defined"
                subtitle="You can now access to your account."
                bottomContent={
                    <Link to="/login" className="formLink">
                        Log in
                    </Link>
                }
            />
        )
    );
};

export default memo(SetPasswordPage);
