type storageIDType = "isGreenBottomBarVisible";

const storage = {
    create(storageId: storageIDType, value: any): void {
        if (!localStorage.getItem(storageId)) {
            localStorage.setItem(storageId, JSON.stringify(value));
        }
    },

    set(storageId: storageIDType, value: any): void {
        localStorage.setItem(storageId, JSON.stringify(value));
    },

    get(storageId: storageIDType): any {
        return JSON.parse(localStorage.getItem(storageId));
    },

    delete(storageId: storageIDType): void {
        localStorage.removeItem(storageId);
    },

    clear() {
        localStorage.clear();
    }
};

export default storage;
