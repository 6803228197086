import { useContext } from "react";
import { useQuery } from "react-query";

import UserTable from "../../content/Dashboard/UserTable/UserTable";
import { ScopeContext } from "../../context/ScopeProvider";
import { DrawerProvider } from "../../context/DrawerContext";

import { CLIENT } from "../../api/client";

const UserPage = () => {
    const { user } = useContext(ScopeContext);
    const {
        data: users = [],
        status,
        isFetching,
        isLoading,
        error,
        refetch
    } = useQuery({
        queryFn: () => CLIENT.UserController.getAll(),
        queryKey: ["get-all-users"],
        refetchOnMount: true,
        enabled: user?.is_manager
    });

    const props = { user, users, status, isFetching, isLoading, error };

    return (
        <DrawerProvider>
            {(params) => (
                <UserTable
                    isOpen={params?.isOpen}
                    setIsOpen={params?.setIsOpen}
                    refetch={refetch}
                    {...props}
                />
            )}
        </DrawerProvider>
    );
};

export default UserPage;
