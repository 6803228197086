import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import { CookiesProvider } from "react-cookie";
import { ScopeProvider } from "./context/ScopeProvider";
import { QueryCache, QueryClient, QueryClientProvider } from "react-query";

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            refetchOnReconnect: false,
            refetchInterval: false,
            refetchIntervalInBackground: false,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            retryOnMount: false
        }
    },
    queryCache: new QueryCache({
        onError: (error: any, query) => {
            if (
                error?.response?.status === 403 &&
                !query?.queryKey.includes("get-current")
            ) {
                queryClient.setQueryData(["get-current"], null);
                queryClient.refetchQueries(["get-current"]);
            }
        }
    })
});

ReactDOM.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <CookiesProvider>
                <ScopeProvider>
                    <Router>
                        <App />
                    </Router>
                </ScopeProvider>
            </CookiesProvider>
        </QueryClientProvider>
    </React.StrictMode>,
    document.getElementById("root")
);
