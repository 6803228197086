import { NavLink } from "react-router-dom";

function LinkCard({
    text,
    link,
    className,
    onClick
}: {
    text: string;
    link?: string;
    className?: string;
    onClick?: () => void;
}) {
    if (link)
        return (
            <div className={`link-card ${className ?? ""}`}>
                <NavLink
                    to={link}
                    target={"_blank"}
                    rel={"noreferrer"}
                    className="link"
                    onClick={onClick}
                >
                    {text}
                </NavLink>
            </div>
        );

    return (
        <div className={`link-card ${className ?? ""}`} onClick={onClick}>
            <button className="link">{text}</button>
        </div>
    );
}

export default LinkCard;
