import LoginContentSection from "../../components/Layouts/LoginContentSection/LoginContentSection";
import LoginPageLayout from "../../components/Layouts/LoginPageLayout/LoginPageLayout";
import CodeValidationForm from "../../components/Molecules/LoginForm/CodeValidationForm";

interface CodeValidationContentProps {
    onFormSubmit: Function;
    error?: string;
    isLoading: boolean;
}

const CodeValidationContent = ({
    onFormSubmit,
    error,
    isLoading
}: CodeValidationContentProps): JSX.Element => (
    <LoginPageLayout className="codeValidationPage">
        <LoginContentSection
            title="Enter your validation code"
            subTitle="Get your code from your 2 factor authentication app."
            error={error}
        >
            <CodeValidationForm
                onFormSubmit={onFormSubmit}
                isLoading={isLoading}
                redirectUrl="/"
            />
        </LoginContentSection>
    </LoginPageLayout>
);

export default CodeValidationContent;
