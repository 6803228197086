import { useContext } from "react";
import { useMutation, useQuery } from "react-query";
import Settings from "../../content/Dashboard/Settings/Settings";

import { CLIENT } from "../../api/client";
import { ScopeContext } from "../../context/ScopeProvider";
import { DrawerProvider } from "../../context/DrawerContext";
import { queryClient } from "../..";

const SettingPage = () => {
    const { user } = useContext(ScopeContext);
    const {
        data: customer,
        status,
        isFetching,
        isLoading,
        error: customerError
    } = useQuery({
        queryFn: () => CLIENT.CustomerController.getCurrent(),
        queryKey: ["get-customer"],
        refetchOnMount: true
    });

    const {
        mutate,
        error: deleteOTPError,
        isSuccess,
        reset
    } = useMutation<any, Error, any>(() => CLIENT.LogController.otpDelete(), {
        onSuccess: () => {
            queryClient.refetchQueries(["get-current"]);
        }
    });

    const handleDeleteOTP = () => {
        mutate(null);

        setTimeout(() => {
            reset();
        }, 1200);
    };
    const settingProps = {
        status,
        customer,
        isFetching,
        isLoading,
        error: customerError || deleteOTPError,
        user
    };

    return (
        <DrawerProvider>
            {(params) => (
                <Settings
                    isOpen={params?.isOpen}
                    setIsOpen={params?.setIsOpen}
                    onDeleteOTP={handleDeleteOTP}
                    success={
                        isSuccess
                            ? "Two Factor activation has been removed"
                            : ""
                    }
                    {...settingProps}
                />
            )}
        </DrawerProvider>
    );
};

export default SettingPage;
