import { memo } from "react";

import LoginContentSection from "../../components/Layouts/LoginContentSection/LoginContentSection";
import LoginPageLayout from "../../components/Layouts/LoginPageLayout/LoginPageLayout";
import RegisterForm from "../../components/Molecules/LoginForm/RegisterForm";

interface RegisterContentProps {
    error?: string;
    onFormSubmit: Function;
    isLoading;
}

const RegisterContent = ({
    error,
    onFormSubmit,
    isLoading
}: RegisterContentProps): JSX.Element => (
    <LoginPageLayout className="loginPage">
        <LoginContentSection
            title="Create an account"
            subTitle="Register and activate your License Portal account"
            error={error}
        >
            <RegisterForm onFormSubmit={onFormSubmit} isLoading={isLoading} />
        </LoginContentSection>
    </LoginPageLayout>
);

export default memo(RegisterContent);
