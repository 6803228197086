import { memo } from "react";

interface DetailRowProps {
    label: string;
    content: any;
}

const DetailRow = ({ label, content }: DetailRowProps): JSX.Element => (
    <tr className="licenseDetail__tableRow">
        <td className="licenseDetail__tableLabel">{label}</td>
        <td className="licenseDetail__tableValue">{content}</td>
    </tr>
);

export default memo(DetailRow);
