// -----------------------------------------------------
// dependencies
// -----------------------------------------------------
import { useEffect, useState } from "react";
import _ from "lodash";
import copy from "copy-to-clipboard";
import raw from "raw.macro";
import ReactMarkdown from "react-markdown";

import Alert from "../../../components/Molecules/Alert/Alert";
import DrawerLicenseDescription from "../Drawer/DrawerLicenseDescription";
import DetailRow from "../../../components/Layouts/Table/DetailRow";
import DetailTable from "../../../components/Layouts/Table/DetailTable";
import FAIcon from "../../../components/Atoms/Icons/FAIcon";
import GenericDetailTable from "./LicenseDetailTable/GenericDetailTable";
import LicenseDetailBody from "../../../components/Layouts/Table/LicenseDetailBody";
import LicenseForm from "../../../components/Molecules/Dashboard/LicenseForm/LicenseForm";
import LicenseStatus from "../LicenseStatus/LicenseStatus";
import LoadingScreen from "../../../components/Layouts/LoadingScreen/LoadingScreen";
import Quota from "../../../components/Molecules/Table/Quota";
import QuotaGroup from "../../../components/Molecules/Table/QuotaGroup";
import SettingCard from "../../../components/Layouts/Setting/SettingCard";
import Tooltip from "../../../components/Molecules/Tooltip/Tooltip";

import { CLIENT } from "../../../api/client";
import { queryClient } from "../../..";
import { getLicenseStatus } from "../../../helpers/getStatus";
import { LicenseOutput } from "../../../api/LicenseController";

import { renderProductIcon } from "../../../helpers/renderProductIcon";
import "./LicenseDetail.scss";
import "../LicenseTable/LicenseTable.scss";

import { iconCopy } from "../../../images/pictograms";
import IconCopy from "../../../components/Atoms/Icons/IconCopy";

const drawerTextContent = raw("../Drawer/textContent.md");

// -----------------------------------------------------
// Main component
// -----------------------------------------------------

interface LicenseDetailProps {
    licenseData: LicenseOutput;
    licenseId: string;
    refetch: Function;
    isLoading: boolean;
    isOpen: boolean;
    setIsOpen: Function;
}

const LicenseDetail = ({
    licenseData,
    licenseId,
    refetch,
    isLoading,
    isOpen,
    setIsOpen
}: LicenseDetailProps): JSX.Element => {
    const [activationKey, setActivationKey] = useState("");
    const [activationSuccess, setActivationSuccess] = useState<boolean>();
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [instanceDataObj, setInstanceDataObj] = useState({});
    const [isKeyCopied, setIsKeyCopied] = useState(false);
    const [clicked, setClicked] = useState(false);

    const openDrawer = () => setIsOpen(true);

    const onChallengeKeySubmit = (values) => {
        setErrorMessage("");
        return CLIENT.LicenseController.generateKey(
            licenseId,
            values.keyActivation
        )
            .then((key) => {
                copy(key);
                setActivationKey(key);
                setActivationSuccess(true);

                refetch();
                queryClient.refetchQueries(["get-all-licenses"]);
            })
            .catch((error) => {
                setActivationSuccess(false);
                setErrorMessage(
                    error?.response?.data?.data ||
                        "It seems we encounter an internal error, could you please retry later, and contact the commercial support if the problem persists."
                );
                throw new Error();
            });
    };

    useEffect(() => {
        if (licenseData && licenseData.instance) {
            setInstanceDataObj((prevState) => ({
                ...prevState,
                ...licenseData?.instance,
                ...licenseData?.instanceData
            }));
        }
    }, [licenseData]);

    const handleCopyClick = () => {
        copy(licenseData.id);
        setClicked(true);
        setTimeout(() => setClicked(false), 2000); // Remove the clicked style after 2 seconds.
    };

    return isLoading ? (
        <LoadingScreen />
    ) : (
        <>
            {licenseData && (
                <>
                    {/* <TableLayout
                        header={<LicenseTableHeader showManage={false} />}
                        content={
                            <LicenseTableRow
                                license={licenseData}
                                showButton={false}
                                backgroundColorFix
                            />
                        }
                    /> */}

                    <section className="licenseDetail licenseDetail__grid">
                        <SettingCard title="License details">
                            <LicenseDetailBody>
                                <DetailTable>
                                    <DetailRow
                                        label="Product"
                                        content={
                                            <Tooltip
                                                content={licenseData.product}
                                            >
                                                <img
                                                    className="licenseDetail__tableIcon"
                                                    src={renderProductIcon(
                                                        licenseData.product
                                                    )}
                                                    alt={licenseData.product}
                                                />
                                            </Tooltip>
                                        }
                                    />
                                    <DetailRow
                                        label="Id"
                                        content={
                                            <div className="detailRow">
                                                <span
                                                    role="button"
                                                    onClick={handleCopyClick}
                                                >
                                                    {licenseData.id}
                                                </span>
                                                <IconCopy
                                                    className={`iconCopy ${
                                                        clicked ? "clicked" : ""
                                                    }`}
                                                />
                                            </div>
                                        }
                                    />
                                    <DetailRow
                                        label="Plan"
                                        content={licenseData.plan}
                                    />
                                    <DetailRow
                                        label="Kind"
                                        content={licenseData.kind}
                                    />
                                    <DetailRow
                                        label="Description"
                                        content={
                                            <>
                                                <div>
                                                    {licenseData.description}
                                                </div>

                                                <button
                                                    className="navigation__link u-mb-16"
                                                    onClick={openDrawer}
                                                >
                                                    update license description
                                                </button>
                                            </>
                                        }
                                    />
                                    <DetailRow
                                        label="Quotas"
                                        content={
                                            <QuotaGroup>
                                                <Quota
                                                    label={
                                                        <FAIcon icon="fa-solid fa-user-group" />
                                                    }
                                                    value={
                                                        licenseData.quotas[
                                                            "users.normal"
                                                        ]
                                                    }
                                                />

                                                <Quota
                                                    label={
                                                        <FAIcon icon="fa-solid fa-building" />
                                                    }
                                                    value={
                                                        licenseData.quotas
                                                            .organisations
                                                    }
                                                />
                                            </QuotaGroup>
                                        }
                                    />
                                    <DetailRow
                                        label="Start Date"
                                        content={licenseData.valid_from}
                                    />
                                    <DetailRow
                                        label="End Date"
                                        content={licenseData.expires_at}
                                    />
                                    <DetailRow
                                        label="Status"
                                        content={
                                            <LicenseStatus
                                                license={licenseData}
                                            />
                                        }
                                    />
                                </DetailTable>
                            </LicenseDetailBody>
                        </SettingCard>

                        {/* {licenseData.instance && (
                            <SettingCard title="Instance details">
                                <LicenseDetailBody
                                    table={
                                        <LicenseDetailTable
                                            license={licenseData}
                                        />
                                    }
                                ></LicenseDetailBody>
                            </SettingCard>
                        )} */}

                        {licenseData &&
                            licenseData.instance &&
                            getLicenseStatus(licenseData) === "Active" && (
                                <SettingCard title="Instance details">
                                    <LicenseDetailBody
                                        table={
                                            <GenericDetailTable
                                                data={_.pick(instanceDataObj, [
                                                    "name",
                                                    "version",
                                                    "date"
                                                ])}
                                            />
                                        }
                                    />
                                </SettingCard>
                            )}
                    </section>
                </>
            )}

            {activationSuccess === true && (
                <Alert
                    content={
                        <span>
                            {isKeyCopied
                                ? "Your activation key has been successfully copied to clipboard"
                                : "Your license has been activated!"}{" "}
                            {!isKeyCopied && (
                                <span
                                    className="licenseDetail__copyKey"
                                    role="button"
                                    onClick={() => {
                                        copy(activationKey);
                                        setIsKeyCopied(true);
                                    }}
                                >
                                    Copy the key to clipboard
                                </span>
                            )}
                        </span>
                    }
                    className="primary"
                    closable
                    onClose={() => setActivationSuccess(undefined)}
                />
            )}

            {activationSuccess === false && (
                <Alert
                    content={errorMessage}
                    className="danger"
                    closable
                    onClose={() => setActivationSuccess(undefined)}
                />
            )}

            {licenseData && getLicenseStatus(licenseData) !== "Expired" && (
                <section className="licenseDetail licenseDetail__grid">
                    <SettingCard
                        title="How can I activate my license?"
                        contentClassName="contentText"
                    >
                        <ReactMarkdown children={drawerTextContent} />
                    </SettingCard>

                    <SettingCard title="License key activation challenge">
                        <LicenseForm onSubmitForm={onChallengeKeySubmit} />
                    </SettingCard>
                </section>
            )}

            <DrawerLicenseDescription
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                id={licenseId}
            />
        </>
    );
};

export default LicenseDetail;
