import {
    createContext,
    Dispatch,
    ReactElement,
    ReactNode,
    SetStateAction,
    useState
} from "react";

type DrawerContextType = {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
};

export const DrawerContext = createContext<DrawerContextType>({
    isOpen: false,
    setIsOpen: () => {}
});

interface DrawerProviderProps {
    children: (value: DrawerContextType) => ReactNode;
}

export const DrawerProvider = ({
    children
}: DrawerProviderProps): ReactElement => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <DrawerContext.Provider value={{ isOpen, setIsOpen }}>
            <DrawerContext.Consumer>{children}</DrawerContext.Consumer>
        </DrawerContext.Provider>
    );
};
