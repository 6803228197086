import { Link } from "react-router-dom";
import { memo } from "react";
import FAIcon from "../../Icons/FAIcon";
import { iconArrowLeft } from "../../../../images/pictograms";

interface GoBackLinkProps {
    link: string;
    label: string;
}

const GoBackLink = ({ link, label }: GoBackLinkProps): JSX.Element => (
    <Link to={link} className="navigation__tab">
        <FAIcon icon={iconArrowLeft} />
        <span className="navigation__tabLabel">{label}</span>
    </Link>
);

export default memo(GoBackLink);
