import { memo, ReactNode } from "react";
import SettingCardBody from "./SettingCardBody";

interface SettingCardProps {
    title: string | string[];
    subTitle?: string;
    children: ReactNode;
    link?: any;
    contentClassName?: string | string[];
    tag?: ReactNode;
}

const SettingCard = ({
    title,
    subTitle,
    children,
    link,
    contentClassName,
    tag
}: SettingCardProps): JSX.Element => (
    <div className="settingCard">
        <div className="settingCard__content">
            <h2 className="settingCard__title">
                {title}&nbsp;{tag}
            </h2>

            {subTitle && <p className="settingCard__subtitle">{subTitle}</p>}

            <SettingCardBody className={contentClassName}>
                {children}
            </SettingCardBody>
        </div>

        {link && <div className="settingTable__bottom">{link}</div>}
    </div>
);

export default memo(SettingCard);
