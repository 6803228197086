import { useState } from "react";
import ReactMarkdown from "react-markdown";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import raw from "raw.macro";

import DrawerLayout from "../../../components/Layouts/DrawerLayout/DrawerLayout";

import { CLIENT } from "../../../api/client";

const drawerTextContent = raw("./drawerCommunityLicenseContent.md");

interface DrawerCommunityLicenseProps {
    isOpen: boolean;
    setIsOpen: Function;
}

const DrawerCommunityLicense = ({
    isOpen,
    setIsOpen
}: DrawerCommunityLicenseProps): JSX.Element => {
    const [errorMessage, setErrorMessage] = useState<string | null>();
    const navigate = useNavigate();

    const { mutate, error, reset, isLoading } = useMutation<any, Error>(
        () => CLIENT.LicenseController.requestCommunity(),
        {
            onSuccess: (data) => {
                setIsOpen(false);
                reset();
                navigate(`/portal/licenses/${data.id}`);
            },
            onError: (err: AxiosError) => {
                setErrorMessage(
                    err?.response.status === 400 && isOpen
                        ? "No more license available, please contact us at contact@strangebee.com"
                        : null
                );
            }
        }
    );

    const onClick = () => mutate();

    return (
        <DrawerLayout
            title="Request a Community License"
            error={errorMessage || error?.message}
            reset={reset}
            buttonText="Get a Community License"
            buttonAction={onClick}
            content={<ReactMarkdown children={drawerTextContent} />}
            checkboxText="Are you sure you want to request a license?"
            isLoading={isLoading}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
        />
    );
};

export default DrawerCommunityLicense;
