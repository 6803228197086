import { useState, memo } from "react";
import { useMutation } from "react-query";
import { AxiosError } from "axios";

import ForgotPasswordContent from "../../content/Login/ForgotPasswordContent";
import SuccessContent from "../../content/Login/SuccessContent";

import { ForgotPasswordFormValuesType } from "../../components/Molecules/LoginForm/ForgotPasswordForm";
import { CLIENT } from "../../api/client";
import { URLS } from "../../api/urls";

import "./ForgotPasswordPage.scss";
import "./SuccessPage";
import { MSG } from "../../data/msg";

const ForgotPasswordPage = (): JSX.Element => {
    const [email, setEmail] = useState<string>();
    const [errMsg, setErrMsg] = useState<string | null>();
    const { mutate, error, isLoading, isSuccess, reset } = useMutation<
        any,
        Error,
        ForgotPasswordFormValuesType
    >((values) => CLIENT.PasswordController.forgotPassword(values.email), {
        onError: (err: AxiosError) => {
            if (err?.response.status === 500) {
                setErrMsg(MSG.err500);
            }
        }
    });

    const onFormSubmit = (values: ForgotPasswordFormValuesType) => {
        setEmail(values.email);
        reset();
        setErrMsg(null);
        mutate(values);
    };

    return (
        (!isSuccess && (
            <ForgotPasswordContent
                onFormSubmit={onFormSubmit}
                isLoading={isLoading}
                error={errMsg ? errMsg : error?.message}
            />
        )) || (
            <SuccessContent
                title="Check your inbox"
                subtitle={`We have just sent you a recovery email at ${email}.<br/>Please click on the link sent to reset your password.`}
                bottomContent={
                    <>
                        Still need help?{" "}
                        <a
                            href={URLS.website.contactPage}
                            className="formLink"
                            target="_blank"
                            rel="noreferrer "
                        >
                            Contact us
                        </a>
                    </>
                }
            />
        )
    );
};

export default memo(ForgotPasswordPage);
