// -----------------------------------------------------
// dependencies
// -----------------------------------------------------
import { useMemo, memo } from "react";
import { Link, useLocation } from "react-router-dom";

import FAIcon from "../../Atoms/Icons/FAIcon";
import LicenseStatus from "../../../content/Dashboard/LicenseStatus/LicenseStatus";
import Quota from "../../Molecules/Table/Quota";
import QuotaGroup from "../../Molecules/Table/QuotaGroup";
import Tooltip from "../../Molecules/Tooltip/Tooltip";

import { LicenseOutput } from "../../../api/LicenseController";

import { renderProductIcon } from "../../../helpers/renderProductIcon";
import { parseDate } from "../../../helpers/dates";
import { classList } from "../../../helpers/className";

// -----------------------------------------------------
// types
// -----------------------------------------------------
export type StatusType = "Expired" | "Not valid yet" | "Pending" | "Active";
interface LicenseTableRowProps {
    license: LicenseOutput;
    showButton?: boolean;
    backgroundColorFix?: boolean;
}

// -----------------------------------------------------
// Main component
// -----------------------------------------------------
const LicenseTableRow = ({
    license,
    showButton = true,
    backgroundColorFix = false
}: LicenseTableRowProps): JSX.Element => {
    const {
        id,
        valid_from,
        expires_at,
        product,
        kind,
        plan,
        install_count,
        description,
        quotas
    } = license;

    const today = new Date().valueOf();
    const start = new Date(valid_from).valueOf();
    const expiration = new Date(expires_at).valueOf();

    const status: StatusType = useMemo(
        () =>
            today > expiration
                ? "Expired"
                : start > today
                ? "Not valid yet"
                : install_count === (null || undefined || 0)
                ? "Pending"
                : "Active",
        [expiration, today, start, install_count]
    );

    const { pathname } = useLocation();

    return (
        <tr
            className={classList("table__row", {
                backgroundFix: backgroundColorFix
            })}
        >
            <td className="table__cell">
                <Tooltip content={product}>
                    <img
                        className="tooltip__img"
                        src={renderProductIcon(product)}
                        alt={product}
                        height={23}
                    />
                    {"  "}
                    {plan}
                </Tooltip>
            </td>
            <td className="table__cell">{kind}</td>
            <td className="table__cell">{description || "-"}</td>
            {/* <td className="table__cell">{plan}</td> */}
            <td className="table__cell">
                <QuotaGroup>
                    <Quota
                        label={<FAIcon icon="fa-solid fa-user-group" />}
                        value={quotas["users.normal"]}
                    />

                    <Quota
                        label={<FAIcon icon="fa-solid fa-building" />}
                        value={quotas.organisations}
                    />
                </QuotaGroup>
            </td>
            <td className="table__cell">{parseDate(valid_from)}</td>
            <td className="table__cell">{parseDate(expires_at)}</td>
            <td className="table__cell">
                <LicenseStatus license={license} />
            </td>
            {showButton && (
                <td className="table__cell">
                    <Link
                        to={`${pathname}/${id}`}
                        className="table__manage-button"
                    >
                        {status !== "Pending" ? "View details" : "Activate"}
                    </Link>
                </td>
            )}
        </tr>
    );
};

export default memo(LicenseTableRow);
