import { ReactNode, memo } from "react";
import LoginContainer from "../LoginContainer/LoginContainer";
import LoginLogo from "../../Atoms/LoginLogo";
import shape from "../../../images/illustrations/Shape.svg";
import { classList } from "../../../helpers/className";

import "./LoginPageLayout.scss";

interface LoginPageLayoutProps {
    children: ReactNode;
    className?: string;
}

const LoginPageLayout = ({
    children,
    className
}: LoginPageLayoutProps): JSX.Element => (
    <div className={classList("login", className)}>
        <LoginContainer>
            <LoginLogo />
            {children}
        </LoginContainer>
        <img src={shape} className="shape" alt="" />
    </div>
);

export default memo(LoginPageLayout);
