import { useContext } from "react";
import { useQuery } from "react-query";

import Home from "../../content/Dashboard/Home";
import { CLIENT } from "../../api/client";
import { ScopeContext } from "../../context/ScopeProvider";

const HomePage = () => {
    const { user, setLicenses } = useContext(ScopeContext);
    const {
        data: customer,
        status: customerQueryStatus,
        isLoading
    } = useQuery({
        queryFn: () => CLIENT.CustomerController.getCurrent(),
        queryKey: ["get-customer"],
        refetchOnMount: true
    });

    const {
        data: licenses = [],
        status: licensesQueryStatus,
        isLoading: isLicensesLoading
    } = useQuery({
        queryFn: () => CLIENT.LicenseController.getAll(),
        queryKey: ["get-all-licenses"],
        refetchOnMount: true,
        onSuccess: (data) => {
            setLicenses(data);
        }
    });

    const {
        data: users = [],
        status: usersQueryStatus,
        isLoading: isUsersLoading
    } = useQuery({
        queryFn: () => CLIENT.UserController.getAll(),
        queryKey: ["get-all-users"],
        refetchOnMount: true,
        enabled: user?.is_manager
    });

    const props = {
        customer,
        customerQueryStatus,
        isLoading,
        user,
        licenses,
        licensesQueryStatus,
        isLicensesLoading,
        users,
        usersQueryStatus,
        isUsersLoading
    };

    return <Home {...props} />;
};

export default HomePage;
