import { memo } from "react";
// * For next version
// import TableCheckbox from "../../Atoms/Table/TableCheckbox";
// * END For next version

interface LicenseTableHeaderProps {
    showManage?: boolean;
}

const LicenseTableHeader = ({
    showManage = true
}: LicenseTableHeaderProps): JSX.Element => (
    <thead className="table__head">
        <tr className="table__headRow">
            <th>Product</th>
            <th>Kind</th>
            <th className="u-cell-width-24">Description</th>
            <th>Quotas</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Status</th>
            {showManage && <th></th>}
        </tr>
    </thead>
);

export default memo(LicenseTableHeader);
