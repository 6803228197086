// -----------------------------------------------------
// Dependencies
// -----------------------------------------------------
import { useState } from "react";
import { useMutation } from "react-query";
import { AxiosError } from "axios";

import DrawerLayout from "../../../components/Layouts/DrawerLayout/DrawerLayout";
import LicenseDescriptionForm, {
    LicenseDescriptionFormValuesType
} from "../../../components/Molecules/LicenseDescriptionForm/LicenseDescriptionForm";

import { CLIENT } from "../../../api/client";

// -----------------------------------------------------
// Context
// -----------------------------------------------------
import { MSG } from "../../../data/msg";
import { queryClient } from "../../..";

// -----------------------------------------------------
// Main component
// -----------------------------------------------------
interface DrawerLicenseDescriptionProps {
    id: string;
    isOpen: boolean;
    setIsOpen: Function;
}
const DrawerLicenseDescription = ({
    id,
    isOpen,
    setIsOpen
}: DrawerLicenseDescriptionProps): JSX.Element => {
    const [errMsg, setErrMsg] = useState<string | null>();

    const { mutate, error, reset, isLoading, isSuccess } = useMutation<
        any,
        Error,
        LicenseDescriptionFormValuesType
    >(
        (formValues) =>
            CLIENT.LicenseController.updateDescription(id, { ...formValues }),
        {
            onSuccess: () => {
                queryClient.refetchQueries(["get-license", id]);

                setTimeout(() => {
                    setIsOpen(false);
                    resetDatas();
                }, 2000);
            },
            onError: (err: AxiosError) => {
                if (err?.response.status === 405) {
                    setErrMsg(MSG.err405);
                }
                if (err?.response.status === 500) {
                    setErrMsg(MSG.err500);
                }
            }
        }
    );

    const resetDatas = () => {
        reset();
        setErrMsg(null);
    };

    const onFormSubmit = (formValues: LicenseDescriptionFormValuesType) => {
        reset();
        mutate(formValues);
    };

    return (
        <DrawerLayout
            title="Edit license description"
            error={errMsg ? errMsg : error?.message}
            reset={resetDatas}
            success={isSuccess ? "New description added" : ""}
            content={
                <LicenseDescriptionForm
                    onFormSubmit={onFormSubmit}
                    callback={reset}
                    isLoading={isLoading}
                    isSuccess={isSuccess}
                    isOpen={isOpen}
                />
            }
            isOpen={isOpen}
            setIsOpen={setIsOpen}
        />
    );
};

export default DrawerLicenseDescription;
