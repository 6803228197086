// -----------------------------------------------------
// dependencies
// -----------------------------------------------------
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import YupPassword from "yup-password";
import useDisabledButton from "../../../hooks/useDisabledButton";

import FormInput from "../../Atoms/Form/Input/Input";
import Button from "../../Atoms/Button/Button";

import { classList } from "../../../helpers/className";
import LoadingScreen from "../../Layouts/LoadingScreen/LoadingScreen";

import "./CodeValidationForm.scss";
import { useEffect } from "react";
import { Link } from "react-router-dom";

YupPassword(yup);

// -----------------------------------------------------
// typescript rules
// -----------------------------------------------------

interface CodeValidationFormProps {
    onFormSubmit: Function;
    isLoading?: boolean;
    buttonClassName?: string;
    buttonText?: string;
    inputText?: string;
    isSuccess?: boolean;
    isOpen?: boolean;
    redirectUrl?: string;
}

export type CodeValidationFormValuesTypes = {
    code: string;
};

// -----------------------------------------------------
// form validation
// -----------------------------------------------------
const formSchema = yup.object().shape({
    code: yup.number().integer().required("Your code is required")
});

const CodeValidationForm = ({
    onFormSubmit,
    isLoading,
    buttonClassName,
    buttonText = "Validate",
    inputText = "Validation Code *",
    isSuccess,
    isOpen,
    redirectUrl
}: CodeValidationFormProps): JSX.Element => {
    const {
        register,
        handleSubmit,
        reset,
        watch,
        formState: { errors }
    } = useForm<CodeValidationFormValuesTypes>({
        resolver: yupResolver(formSchema)
    });

    const handleFormSubmit = (values: CodeValidationFormValuesTypes) =>
        onFormSubmit(values.code);

    const { code } = watch();
    const { isButtonDisabled } = useDisabledButton([code], true);

    useEffect(() => {
        reset();

        if (!isOpen) {
            reset();
        }
    }, [reset, isOpen]);

    useEffect(() => {
        if (isSuccess) reset();
    }, [isSuccess, reset]);

    return (
        <form
            autoComplete="off"
            className="loginForm"
            onSubmit={handleSubmit((data) => handleFormSubmit(data))}
            noValidate
        >
            <FormInput
                type="number"
                name="code"
                placeholder={inputText}
                className={classList({ error: !!errors.code })}
                error={errors.code?.message}
                register={register}
                inputClassName="noArrow"
            />

            <div
                className={classList(
                    redirectUrl ? "loginForm__row" : "loginForm__row--center"
                )}
            >
                {redirectUrl && (
                    <Link to={redirectUrl} className="formLink">
                        Go to the Login page
                    </Link>
                )}

                <Button
                    className={classList("button--primary", buttonClassName)}
                    disabled={isButtonDisabled}
                >
                    {isLoading ? <LoadingScreen contained /> : buttonText}
                </Button>
            </div>
        </form>
    );
};

export default CodeValidationForm;
