import AccountActivationForm from "../../components/Molecules/LoginForm/AccountActivationForm";
import LoginContentSection from "../../components/Layouts/LoginContentSection/LoginContentSection";
import LoginPageLayout from "../../components/Layouts/LoginPageLayout/LoginPageLayout";

interface SetPasswordContentProps {
    error?: string;
    onFormSubmit: Function;
    isLoading: boolean;
}

const SetPasswordContent = ({
    error,
    onFormSubmit,
    isLoading
}: SetPasswordContentProps): JSX.Element => (
    <LoginPageLayout className="setPasswordPage">
        <LoginContentSection
            title="Create a password for your account"
            error={error}
        >
            <AccountActivationForm
                onFormSubmit={onFormSubmit}
                isLoading={isLoading}
            />
        </LoginContentSection>
    </LoginPageLayout>
);

export default SetPasswordContent;
