import { ReactNode, memo } from "react";

interface TableLayoutProps {
    header: ReactNode;
    content?: ReactNode;
    children?: ReactNode | ReactNode[];
}

const TableLayout = ({
    header,
    content,
    children
}: TableLayoutProps): JSX.Element => (
    <table className="table">
        {header}

        <tbody className="table__body">
            {content}
            {children}
        </tbody>
    </table>
);

export default memo(TableLayout);
