import { memo, ReactNode } from "react";

interface LicenseDetailBodyProps {
    table?: ReactNode;
    form?: ReactNode;
    children?: ReactNode;
}

const LicenseDetailBody = ({
    table,
    form,
    children
}: LicenseDetailBodyProps): JSX.Element => (
    <div className="licenseDetail__content">
        <div className="licenseDetail__informations">
            {table}
            {children}
        </div>

        {form && <div className="licenseDetail__formSection">{form}</div>}
    </div>
);

export default memo(LicenseDetailBody);
