// -----------------------------------------------------
// dependencies
// -----------------------------------------------------
import { memo, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import YupPassword from "yup-password";
import useDisabledButton from "../../../hooks/useDisabledButton";

import Button from "../../Atoms/Button/Button";
import LoadingScreen from "../../Layouts/LoadingScreen/LoadingScreen";
import FormInput from "../../Atoms/Form/Input/Input";

import { iconUser, iconMail } from "../../../images/pictograms";
import { classList } from "../../../helpers/className";

import "./NewUserForm.scss";

YupPassword(yup);

// -----------------------------------------------------
// typescript rules
// -----------------------------------------------------
export interface NewUserFormProps {
    onFormSubmit: (values: NewUserFormValuesType) => void;
    callback?: Function;
    isLoading?: boolean;
    isOpen: boolean;
}

export type NewUserFormValuesType = {
    firstname: string;
    lastname: string;
    email: string;
};

// -----------------------------------------------------
// form validation
// -----------------------------------------------------
const NAME_REGEX =
    /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;

const formSchema = yup.object().shape({
    firstname: yup
        .string()
        .min(2, "First name should be at least 2 characters")
        .required("First name is required")
        .matches(NAME_REGEX, "Please enter a valid Firstname"),
    lastname: yup
        .string()
        .min(2, "Last name should be at least 2 characters")
        .required("Last name is required")
        .matches(NAME_REGEX, "Please enter a valid Lastname"),
    email: yup
        .string()
        .email("Email must be a valid address")
        .lowercase("Email adress should be to lower case")
        .required("Email is required"),
    note: yup.string()
});

// -----------------------------------------------------
// component
// -----------------------------------------------------
const NewUserForm = ({
    onFormSubmit,
    callback,
    isLoading,
    isOpen
}: NewUserFormProps): JSX.Element => {
    const {
        register,
        handleSubmit,
        reset,
        watch,
        formState: { errors }
    } = useForm<NewUserFormValuesType>({ resolver: yupResolver(formSchema) });

    const handleFormSubmit = (values: NewUserFormValuesType) => {
        onFormSubmit(values);
    };

    const { firstname, lastname, email } = watch();
    const { isButtonDisabled } = useDisabledButton(
        [firstname, lastname, email],
        true
    );

    useEffect(() => {
        reset();

        if (!isOpen) {
            reset();

            if (callback) {
                callback();
            }
        }
    }, [reset, isOpen, callback]);

    return (
        <form
            autoComplete="off"
            className="newUserForm"
            onSubmit={handleSubmit((data) => handleFormSubmit(data))}
        >
            <FormInput
                name="firstname"
                placeholder="First name"
                className={classList({ error: !!errors.firstname })}
                register={register}
                icon={iconUser}
                error={errors.firstname?.message}
            />

            <FormInput
                name="lastname"
                placeholder="Last name"
                className={classList({ error: !!errors.lastname })}
                register={register}
                icon={iconUser}
                error={errors.lastname?.message}
            />

            <FormInput
                type="email"
                name="email"
                placeholder="Email"
                className={classList({ error: !!errors.email })}
                register={register}
                icon={iconMail}
                error={errors.email?.message}
            />

            <Button
                className={classList("button-outline--primary")}
                disabled={isButtonDisabled}
            >
                {isLoading ? (
                    <LoadingScreen contained />
                ) : (
                    "Confirm & Create New"
                )}
            </Button>
        </form>
    );
};

export default memo(NewUserForm);
