import { useQuery } from "react-query";
import { NavLink, useParams } from "react-router-dom";
import { CLIENT } from "../../api/client";
import SuccessContent from "../../content/Login/SuccessContent";
import "../Login/SuccessPage.scss";
import { URLS } from "../../api/urls";
export default function UserEmailVerified() {
    const params = useParams();
    const token = params?.token as string;
    const { isSuccess, isError, isLoading } = useQuery(
        ["verify_email", token],
        {
            queryFn: () => CLIENT.UserController.verify_email(token),
            refetchOnMount: true
        }
    );

    if (isSuccess)
        return (
            <SuccessContent
                title="Your email address has been verified"
                subtitle={`
                Thank you for your interest in StrangeBee solutions. We will review your registration and get back to you very soon 🐝
        `}
                bottomContent={
                    <p className="formText">
                        In the meantime, check out our latest news on{" "}
                        <NavLink
                            to={URLS.community.blog}
                            target={"_blank"}
                            rel={"noreferrer"}
                            className={"formLink"}
                        >
                            our blog
                        </NavLink>
                        .
                    </p>
                }
            />
        );

    if (isError) return <div>Could not verify your email address</div>;
    if (isLoading) return <div>Loading...</div>;
}
