import { useState, useEffect } from "react";

type formFieldType = string | number | null;

function useDisabledButton(formFields: formFieldType[], defaultState: boolean) {
    const [isButtonDisabled, setIsButtonDisabled] = useState(defaultState);

    const checkIfFieldIsEmpty = (field) =>
        field !== "" && field !== undefined && field !== null;

    useEffect(() => {
        if (formFields.every(checkIfFieldIsEmpty)) {
            setIsButtonDisabled(!defaultState);
        } else {
            setIsButtonDisabled(defaultState);
        }
    }, [defaultState, formFields]);

    return { isButtonDisabled };
}

export default useDisabledButton;
