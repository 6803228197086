import { memo } from "react";
import { ReactElement } from "react-markdown/lib/react-markdown";

interface HomeHeaderProps {
    title?: string;
    info?: string;
    children?: ReactElement;
}

const HomeHeader = ({
    title = "Welcome, Guest !",
    info = `You are now logged into StrangeBee License Portal as a guest.`,
    children
}: HomeHeaderProps): JSX.Element => (
    <div className="home__header">
        <p
            className="home__grettings"
            dangerouslySetInnerHTML={{ __html: title }}
        />
        <p
            className="home__logInfos"
            dangerouslySetInnerHTML={{ __html: info }}
        />
        {children}
    </div>
);

export default memo(HomeHeader);
