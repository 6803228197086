import { memo } from "react";

const IconForbidden = () => (
    <svg
        className="button-iconForbidden"
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M11 0.5C5.20156 0.5 0.5 5.20156 0.5 11C0.5 16.7984 5.20156 21.5 11 21.5C16.7984 21.5 21.5 16.7984 21.5 11C21.5 5.20156 16.7984 0.5 11 0.5ZM11 19.7188C6.18594 19.7188 2.28125 15.8141 2.28125 11C2.28125 8.91406 3.01484 6.99688 4.23828 5.49688L16.5031 17.7617C15.0031 18.9852 13.0859 19.7188 11 19.7188ZM17.7617 16.5031L5.49688 4.23828C6.99688 3.01484 8.91406 2.28125 11 2.28125C15.8141 2.28125 19.7188 6.18594 19.7188 11C19.7188 13.0859 18.9852 15.0031 17.7617 16.5031Z" />
    </svg>
);

export default memo(IconForbidden);
