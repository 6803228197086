import { memo, ReactNode } from "react";

interface DetailTableProps {
    children: ReactNode | ReactNode[];
}

const DetailTable = ({ children }: DetailTableProps): JSX.Element => (
    <table className="licenseDetail__table">
        <tbody>{children}</tbody>
    </table>
);

export default memo(DetailTable);
