import { ChangeEventHandler, memo } from "react";

import { classList } from "../../../../helpers/className";
import "./Input.scss";

interface SimpleTextInputProps {
    placeholder?: string;
    value?: string;
    onChange: ChangeEventHandler;
    className?: string | string[];
}

const SimpleTextInput = ({
    placeholder,
    value,
    onChange,
    className
}: SimpleTextInputProps): JSX.Element => {
    return (
        <input
            type="text"
            className={classList(["formInput", className])}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
        />
    );
};

export default memo(SimpleTextInput);
