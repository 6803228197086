import { memo } from "react";
import { classList } from "../../../helpers/className";
import "./Tag.scss";

interface TagProps {
    text: string;
    className?: string;
}

const Tag = ({ text, className }: TagProps): JSX.Element => (
    <span className={classList("tag", className)}>{text}</span>
);

export default memo(Tag);
