// -----------------------------------------------------
// dependencies
// -----------------------------------------------------
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import YupPassword from "yup-password";
import useDisabledButton from "../../../hooks/useDisabledButton";

import Button from "../../Atoms/Button/Button";
import LoadingScreen from "../../Layouts/LoadingScreen/LoadingScreen";
import FormInput from "../../Atoms/Form/Input/Input";

import { iconEdit } from "../../../images/pictograms";
import { classList } from "../../../helpers/className";

import "../NewUserForm/NewUserForm.scss";

YupPassword(yup);

// -----------------------------------------------------
// typescript rules
// -----------------------------------------------------
export interface LicenseDescriptionFormProps {
    onFormSubmit: (values: LicenseDescriptionFormValuesType) => void;
    callback?: Function;
    isLoading?: boolean;
    isSuccess?: boolean;
    isOpen?: boolean;
}

export type LicenseDescriptionFormValuesType = {
    description: string;
};

// -----------------------------------------------------
// form validation
// -----------------------------------------------------
const formSchema = yup.object().shape({
    description: yup
        .string()
        .min(2, "Description should be at least 2 characters")
        .max(255, "Description take 255 characters maximum")
        .required("Description is required")
});

// -----------------------------------------------------
// component
// -----------------------------------------------------
const LicenseDescriptionForm = ({
    onFormSubmit,
    callback,
    isLoading,
    isSuccess,
    isOpen
}: LicenseDescriptionFormProps): JSX.Element => {
    const {
        register,
        handleSubmit,
        reset,
        watch,
        formState: { errors }
    } = useForm<LicenseDescriptionFormValuesType>({
        resolver: yupResolver(formSchema)
    });

    const handleFormSubmit = (values: LicenseDescriptionFormValuesType) => {
        onFormSubmit(values);
    };

    const { description } = watch();
    const { isButtonDisabled } = useDisabledButton([description], true);

    useEffect(() => {
        reset();

        if (!isOpen) {
            reset();

            if (callback) {
                callback();
            }
        }
    }, [reset, isOpen, callback]);

    useEffect(() => {
        if (isSuccess) reset();
    }, [isSuccess, reset]);

    return (
        <form
            autoComplete="off"
            className="LicenseDescriptionForm"
            onSubmit={handleSubmit((data) => handleFormSubmit(data))}
        >
            <FormInput
                name="description"
                placeholder="New description"
                className={classList({ error: !!errors.description })}
                register={register}
                icon={iconEdit}
                error={errors.description?.message}
                textLimit={255}
            />

            <Button
                className={classList("button-outline--primary")}
                disabled={isButtonDisabled}
            >
                {isLoading ? <LoadingScreen contained /> : "Update description"}
            </Button>
        </form>
    );
};

export default LicenseDescriptionForm;
