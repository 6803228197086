import { memo, ReactNode } from "react";

interface SettingTableProps {
    children: ReactNode;
}

const SettingTable = ({ children }: SettingTableProps): JSX.Element => (
    <div className="settingTable">{children}</div>
);

export default memo(SettingTable);
