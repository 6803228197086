import { MouseEventHandler, ReactNode, useState, memo } from "react";
import { classList } from "../../../helpers/className";
import { iconChevronRight } from "../../../images/pictograms";
import Alert from "../../Molecules/Alert/Alert";
import CheckBox from "../../Atoms/Form/CheckBox/CheckBox";

import "./DrawerLayout.scss";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import DrawerHeader from "../../Molecules/Drawer/DrawerHeader";
import FAIcon from "../../Atoms/Icons/FAIcon";

interface DrawerLayoutProps {
    success?: string | ReactNode;
    error?: string | ReactNode;
    content: string | ReactNode;
    pageLink?: string;
    pageLinkName?: string;
    buttonText?: string;
    buttonAction?: MouseEventHandler<HTMLButtonElement>;
    title: string;
    checkboxText?: string;
    reset?: Function;
    isLoading?: boolean;
    isOpen?: boolean;
    setIsOpen?: Function;
}

const DrawerLayout = ({
    success,
    error,
    content,
    pageLink,
    pageLinkName,
    buttonText,
    buttonAction,
    title,
    checkboxText,
    reset = () => {},
    isLoading,
    isOpen,
    setIsOpen
}: DrawerLayoutProps): JSX.Element => {
    const [isDisabled, setIsDisabled] = useState(true);

    const toggleButton = () => {
        setIsDisabled(!isDisabled);
    };

    const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        toggleButton();
        buttonAction(e);
    };

    const toggleClose = () => {
        reset();
        setIsOpen(false);
    };

    let className = isOpen ? "open" : "";

    return (
        <div className={classList("drawer", className)}>
            <DrawerHeader close={toggleClose} title={title} />

            <div className="drawer__content">
                {success && (
                    <Alert
                        content={success}
                        className={["center", "primary"]}
                    />
                )}
                {error && (
                    <Alert content={error} className={["danger", "center"]} />
                )}
                {content}

                {pageLink && (
                    <p>
                        <a
                            className="drawer__link"
                            href={pageLink}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {pageLinkName}{" "}
                            <FAIcon
                                icon={iconChevronRight}
                                className="drawer__link-icon"
                            />
                        </a>
                    </p>
                )}

                {checkboxText && (
                    <CheckBox
                        label={checkboxText}
                        onChange={toggleButton}
                        checked={!isDisabled}
                    />
                )}

                {buttonText &&
                    (isLoading ? (
                        <LoadingScreen />
                    ) : (
                        <p>
                            <button
                                className={classList("button--primary", {
                                    disabled: isDisabled && checkboxText
                                })}
                                onClick={onClick}
                            >
                                {buttonText}
                            </button>
                        </p>
                    ))}
            </div>
        </div>
    );
};

export default memo(DrawerLayout);
