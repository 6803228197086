// ----------------------------------------
// Dependencies
// ----------------------------------------
import { useContext, useState, memo } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router";

import CodeValidationContent from "../../content/Login/CodeValidationContent";
import LoginContent from "../../content/Login/LoginContent";
import { LoginFormValuesType } from "../../components/Molecules/LoginForm/LoginForm";

import { ScopeContext } from "../../context/ScopeProvider";
import { CLIENT } from "../../api/client";
import { queryClient } from "../..";
import { MSG } from "../../data/msg";

import "./LoginPage.scss";

// ----------------------------------------
// Main component
// ----------------------------------------
const LoginPage = (): JSX.Element => {
    const [showOTP, setShowOTP] = useState<boolean>(false);
    const [errMsg, setErrMsg] = useState<string | null>();
    const { setUser } = useContext(ScopeContext);
    const navigate = useNavigate();

    const {
        mutate: mutateLogin,
        error: errorLogin,
        isLoading: isLoadingLogin,
        reset: resetLogin
    } = useMutation<any, Error, LoginFormValuesType>(
        (credentials) => CLIENT.LogController.login(credentials),
        {
            onSuccess: (res) => {
                const user = res.data.data[0];
                setUser(user);
                queryClient.refetchQueries(["get-current"]);
                navigate("/");
            },
            onError: (err: any) => {
                const { status } = err.response;

                switch (status) {
                    case 302:
                        setShowOTP(true);
                        break;
                    case 500:
                        setErrMsg(MSG.err500);
                        break;
                    default:
                        setErrMsg(MSG.err401);
                }
            }
        }
    );

    const {
        mutate: mutate2fa,
        error: error2fa,
        isLoading: isLoading2fa,
        reset: reset2fa
    } = useMutation<any, Error, string>(
        (token) => CLIENT.LogController.otpVerify(token),
        {
            onSuccess: () => {
                queryClient.refetchQueries(["get-current"]);
                navigate("/");
            },
            onError: (err: any) => {
                const { status } = err.response;

                switch (status) {
                    case 400:
                        setErrMsg("Wrong code");
                        break;
                    case 403:
                        setErrMsg("Unable to validate your code");
                        break;
                    case 500:
                        setErrMsg(MSG.err500);
                        break;
                    default:
                        setErrMsg(MSG.invalidOTPCode);
                }
            }
        }
    );

    const onLoginFormSubmit = (credentials: LoginFormValuesType) => {
        resetLogin();
        setErrMsg(null);
        mutateLogin(credentials);
    };

    const onOTPSubmit = (token: string) => {
        reset2fa();
        setErrMsg(null);
        mutate2fa(token);
    };

    return showOTP ? (
        <CodeValidationContent
            error={errMsg ? errMsg : error2fa?.message}
            onFormSubmit={onOTPSubmit}
            isLoading={isLoading2fa}
        />
    ) : (
        <LoginContent
            error={errMsg ? errMsg : errorLogin?.message}
            onFormSubmit={onLoginFormSubmit}
            isLoading={isLoadingLogin}
        />
    );
};

export default memo(LoginPage);
