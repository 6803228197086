// ----------------------------------------
// Dependencies
// ----------------------------------------
import { memo, useContext, useState } from "react";

import Alert from "../../../components/Molecules/Alert/Alert";
import ButtonLink from "../../../components/Atoms/Button/ButtonLink";
import DrawerFormUser from "../Drawer/DrawerFormUser";
import ErrorScreen from "../../../components/Layouts/ErrorScreen/ErrorScreen";
import LoadingScreen from "../../../components/Layouts/LoadingScreen/LoadingScreen";
import TableLayout from "../../../components/Layouts/Table/TableLayout";
import TableTopRow from "../../../components/Atoms/Table/TableTopRow";
import UserTableHeader from "../../../components/Layouts/Table/UserTableHeader";
import UserTableRow from "../../../components/Layouts/Table/UserTableRow";

import { CLIENT } from "../../../api/client";
import { UserOutput } from "../../../api/UserController";
import { iconNewUser } from "../../../images/pictograms";
import { ScopeContext } from "../../../context/ScopeProvider";

// ----------------------------------------
// Main component
// ----------------------------------------
interface UserTableProps {
    user: UserOutput;
    users: UserOutput[] | [];
    status: "loading" | "success" | "error" | "idle";
    isFetching: boolean;
    isLoading: boolean;
    error: any;
    isOpen: boolean;
    setIsOpen: Function;
    refetch: Function;
}

const UserTable = ({
    user,
    users,
    status,
    isFetching,
    isLoading,
    error,
    isOpen,
    setIsOpen,
    refetch
}: UserTableProps): JSX.Element => {
    const handleUserForm = () => {
        setIsOpen(true);
    };
    const [reactivationSuccess, setReactivationSuccess] = useState<{
        success: boolean;
        user?: string;
    }>();

    const sendActivationEmail = (user: UserOutput) => {
        CLIENT.UserController.reactivate(user?.id)
            .then(() => {
                setReactivationSuccess({
                    success: true,
                    user: user?.email
                });
            })
            .catch(() => {
                setReactivationSuccess({
                    success: false,
                    user: user?.email
                });
            });
    };

    const deleteUser = (userId: string) => {
        CLIENT.UserController.delete(userId).then(() => {
            refetch();
        });
    };

    const onAlertClose = () => {
        setReactivationSuccess(undefined);
    };

    const content = users.map((userItem) => (
        <UserTableRow
            key={userItem.id}
            user={userItem}
            userIsManager={user?.is_manager}
            connectedUser={user}
            onUserActivationClick={() => sendActivationEmail(userItem)}
            onDeleteUserClick={() => deleteUser(userItem.id)}
            hasUsers={users.length > 1}
        />
    ));

    if (status === "loading" || isFetching || isLoading) {
        return <LoadingScreen />;
    }

    if (users.length === 0 || !users.length) {
        return <ErrorScreen message="You don't seem to have any users yet." />;
    }

    return (
        <>
            {reactivationSuccess?.success === true && (
                <Alert
                    className="primary"
                    closable
                    content={
                        <>
                            {
                                "Account activation email has been sent successfully to "
                            }
                            <strong>{reactivationSuccess.user}</strong>
                        </>
                    }
                    onClose={onAlertClose}
                />
            )}
            {reactivationSuccess?.success === false && (
                <Alert
                    className="danger"
                    closable
                    content={
                        <>
                            {
                                "An error occurred while sending account activation email to "
                            }
                            <strong>{reactivationSuccess.user}</strong>
                        </>
                    }
                    onClose={onAlertClose}
                />
            )}

            {(error && (
                <Alert
                    content="Unable to get your user list"
                    className={["danger", "center"]}
                />
            )) || (
                <>
                    {user?.is_manager && (
                        <TableTopRow>
                            <ButtonLink
                                title="Add users"
                                icon="fa-regular fa-user-plus"
                                onClick={handleUserForm}
                            />
                        </TableTopRow>
                    )}

                    <TableLayout
                        header={
                            <UserTableHeader
                                userIsManager={user?.is_manager}
                                hasUsers={content?.length > 1}
                            />
                        }
                        content={content}
                    />

                    {user?.is_manager && (
                        <DrawerFormUser isOpen={isOpen} setIsOpen={setIsOpen} />
                    )}
                </>
            )}
        </>
    );
};

export default memo(UserTable);
