import { memo } from "react";

import ForgotPasswordForm from "../../components/Molecules/LoginForm/ForgotPasswordForm";
import LoginContentSection from "../../components/Layouts/LoginContentSection/LoginContentSection";
import LoginPageLayout from "../../components/Layouts/LoginPageLayout/LoginPageLayout";

interface ForgotPasswordContentProps {
    onFormSubmit: Function;
    isLoading: boolean;
    error?: string;
}

const ForgotPasswordContent = ({
    onFormSubmit,
    isLoading,
    error
}: ForgotPasswordContentProps): JSX.Element => (
    <LoginPageLayout className="resetPasswordPage">
        <LoginContentSection
            title="Reset your password"
            subTitle="We need to know you first"
            error={error}
        >
            <ForgotPasswordForm
                onFormSubmit={onFormSubmit}
                isLoading={isLoading}
            />
        </LoginContentSection>
    </LoginPageLayout>
);

export default memo(ForgotPasswordContent);
