// -----------------------------------------------------
// Dependencies
// -----------------------------------------------------
import { useContext, useEffect } from "react";
import { Route, Routes, Navigate } from "react-router";

// -----------------------------------------------------
// Login Pages
// -----------------------------------------------------
import LoginPage from "./pages/Login/LoginPage";
import RegisterPage from "./pages/Login/RegisterPage";
import ForgotPasswordPage from "./pages/Login/ForgotPasswordPage";
import AccountActivationPage from "./pages/Login/AccountActivationPage";
import SetPasswordPage from "./pages/Login/SetPasswordPage";

// -----------------------------------------------------
// Dashboard pages
// -----------------------------------------------------
import Dashboard from "./pages/Dashboard";

// -----------------------------------------------------
// Context
// -----------------------------------------------------
import { ScopeContext } from "./context/ScopeProvider";

// -----------------------------------------------------
// Types
// -----------------------------------------------------
import { CLIENT } from "./api/client";
import { useQuery } from "react-query";
import storage from "./helpers/storage";
import UserEmailVerified from "./pages/_/UserEmailVerified";

const App = () => {
    const { setUser } = useContext(ScopeContext);

    const { status, data } = useQuery({
        queryFn: () => CLIENT.UserController.getCurrent(),
        queryKey: ["get-current"],
        onSuccess: (user) => setUser(user),
        onError: () => setUser(null),
        refetchOnMount: true
    });

    useEffect(() => {
        if (!storage.get("isGreenBottomBarVisible")) {
            storage.create("isGreenBottomBarVisible", true);
        }
    }, []);

    return (
        <Routes>
            {status === "error" && (
                <>
                    <Route path="/login" element={<LoginPage />} />

                    <Route
                        path="/account/register"
                        element={<RegisterPage />}
                    />

                    <Route
                        path="/account/email_verified/:token"
                        element={<UserEmailVerified />}
                    />

                    {/* Page used to request a password reset (forgot password)*/}
                    <Route
                        path="/account/forgot_password"
                        element={<ForgotPasswordPage />}
                    />

                    {/* Page used to enter a new password after forgot password*/}
                    <Route
                        path="/account/set_password/:id/:token"
                        element={<SetPasswordPage />}
                    />

                    {/* Page used to display an activation form after user creation*/}
                    <Route
                        path="/account/activate/:id/:token"
                        element={<AccountActivationPage />}
                    />

                    <Route path="*" element={<Navigate to="/login" />} />
                </>
            )}

            {data && (
                <>
                    <Route path="/portal/*" element={<Dashboard />} />
                    <Route path="*" element={<Navigate to="/portal" />} />
                </>
            )}
        </Routes>
    );
};

export default App;
