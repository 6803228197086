import copy from "copy-to-clipboard";

import Button from "../../Atoms/Button/Button";
import { iconCopy } from "../../../images/pictograms";

interface QRCodeUrlProps {
    url: string;
    callback?: Function;
    buttonText?: string;
}

const QRCodeUrl = ({
    url,
    callback,
    buttonText = "Or copy url to clipbard"
}: QRCodeUrlProps): JSX.Element => {
    const onClick = () => {
        copy(url);
        if (callback) callback();
    };
    return (
        <>
            <div className="QRCode__urlContainer">
                <div className="QRCode__urlInput">
                    <p className="QRCode__url">{url}</p>
                </div>
                <Button
                    onClick={onClick}
                    className="navigation__link"
                    icon={iconCopy}
                >
                    {buttonText}
                </Button>
            </div>
        </>
    );
};

export default QRCodeUrl;
