import { memo } from "react";

interface HeaderUserProps {
    userName: string;
    userPicture?: string;
}

const HeaderUser = ({
    userName,
    userPicture
}: HeaderUserProps): JSX.Element => (
    <span className="header__user">
        <p className="header__username">{userName}</p>
        {userPicture && (
            <figure className="header__userPicture">
                <img
                    className="header__userPictureImage"
                    src={userPicture}
                    alt="Customer name"
                />
            </figure>
        )}
    </span>
);

export default memo(HeaderUser);
