import { ReactNode, useState, memo } from "react";
import { classList } from "../../../helpers/className";
import { iconCloseCircle } from "../../../images/pictograms";
import FAIcon from "../../Atoms/Icons/FAIcon";

import "./Alert.scss";

interface FactoryCloseButtonProps {
    onClick: any;
}

const FactoryCloseButton = memo(
    ({ onClick }: FactoryCloseButtonProps): JSX.Element => (
        <button className="alert__close" onClick={onClick}>
            <FAIcon icon={iconCloseCircle} className="alert__closeIcon" />
        </button>
    )
);

interface AlertProps {
    content: ReactNode;
    className?: string | string[];
    closable?: boolean;
    onClose?: () => void;
}

const Alert = ({
    content,
    className,
    closable,
    onClose
}: AlertProps): JSX.Element => {
    const [isVisible, setIsVisible] = useState(true);

    return (
        <div
            className={classList("alert", className, {
                hide: !isVisible
            })}
        >
            <p className="alert__text">{content}</p>

            {closable && (
                <FactoryCloseButton
                    onClick={() => {
                        setIsVisible(false);
                        onClose?.();
                    }}
                />
            )}
        </div>
    );
};

export default memo(Alert);
