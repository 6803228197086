import { memo } from "react";
import { NavLink } from "react-router-dom";
import { navigationTabType } from "../../../../content/Dashboard/Navigation/navigationTabs";
import FAIcon from "../../Icons/FAIcon";

interface NavigationTabProps {
    tab: navigationTabType;
}

const NavigationTab = ({ tab }: NavigationTabProps): JSX.Element => (
    <li>
        <NavLink to={`${tab.id}`} className="navigation__tab">
            <FAIcon icon={tab.img} />
            <span className="navigation__tabLabel">{tab.label}</span>
        </NavLink>
    </li>
);

export default memo(NavigationTab);
