import { useState } from "react";
import FormControl from "../FormControl/FormControl";
import FormError from "../FormError/FormError";
import { classList } from "../../../../helpers/className";
import FAIcon from "../../Icons/FAIcon";
import { iconEye, iconEyeSlash } from "../../../../images/pictograms";

import "./Input.scss";

interface FormInputProps {
    name: string;
    type?: string;
    placeholder?: string;
    icon?: string;
    className?: string;
    register: any;
    required?: boolean;
    error?: string;
    list?: string | null;
    textLimit?: number;
    inputClassName?: string;
}

const FormInput = ({
    name,
    type = "text",
    placeholder = "input",
    icon,
    className,
    register,
    required,
    error,
    list,
    textLimit,
    inputClassName
}: FormInputProps): JSX.Element => {
    const [isPassword, setIsPassword] = useState(true);
    let content;

    if (type === "textarea") {
        content = (
            <textarea
                className={classList(["formTextarea", inputClassName])}
                placeholder={placeholder}
                {...register(name, { required })}
            />
        );
    } else if (type === "password") {
        content = (
            <>
                <input
                    type={isPassword ? type : "text"}
                    className={classList([
                        "formInput",
                        "formInput--password",
                        inputClassName
                    ])}
                    placeholder={placeholder}
                    maxLength={textLimit}
                    {...register(name, { required })}
                />
                <button
                    className="formInput__passwordButton"
                    type="button"
                    tabIndex={-1}
                    onClick={() => setIsPassword(!isPassword)}
                >
                    <FAIcon
                        icon={isPassword ? iconEyeSlash : iconEye}
                        className="formInput__passwordIcon"
                    />
                </button>
            </>
        );
    } else {
        content = (
            <input
                type={type}
                list={list}
                className={classList(["formInput", inputClassName])}
                placeholder={placeholder}
                maxLength={textLimit}
                {...register(name, { required })}
            />
        );
    }

    const hasIcon = !!icon;
    return (
        <FormControl withIcon={hasIcon} className={classList(className)}>
            {hasIcon && (
                <FAIcon icon={icon} className={classList("formInput__icon")} />
            )}
            {content}
            {error && <FormError message={error} />}
        </FormControl>
    );
};

export default FormInput;
