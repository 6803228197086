import { useContext } from "react";
import "./CommunitySection.scss";
import { HomeCardCtaWithIcon } from "../HomeCards";
import { ScopeContext } from "../../../../context/ScopeProvider";
import {
    iconCommments,
    iconJournal,
    iconShare,
    iconSlackUsers
} from "../../../../images/pictograms";
import { URLS } from "../../../../api/urls";

type CardInfo = {
    title: string;
    icon: string;
    text: string;
    links: { text: string; link: string }[];
};

const CommunitySection = () => {
    const { isPremiumUser } = useContext(ScopeContext);
    const cardsInfos = [
        isPremiumUser
            ? {
                  title: "Share a feedback with us",
                  icon: iconCommments,
                  text: "At StrangeBee, the customer voice drives all our development. You have an idea, a feature request? Help us to build the best cybersecurity case management tool.",
                  links: [
                      {
                          text: "Create a feature request",
                          link: URLS.community.feature_request 
                      }
                  ]
              }
            : null,
        isPremiumUser
            ? {
                  title: "Join the slack community",
                  icon: iconSlackUsers,
                  text: "Share with other customer about your use of TheHive and level up your automation skils.",
                  links: [
                      {
                          text: "Join the community",
                          link: URLS.community.slack
                      }
                  ]
              }
            : null,
        {
            title: "Latest news on the blog",
            icon: iconJournal,
            text: "TheHive Version 5.3 is now available ! Discover the new features included",
            links: [
                {
                    text: "Read article",
                    link: URLS.community.blog
                }
            ]
        },
        {
            title: "Our social networks",
            icon: iconShare,
            text: "",
            links: [
                {
                    text: "X",
                    link: URLS.community.socials.X
                },
                {
                    text: "LinkedIn",
                    link: URLS.community.socials.linkedIn
                },
                {
                    text: "Mastodon",
                    link: URLS.community.socials.mastodon
                }
            ]
        }
    ].filter((cardInfo) => cardInfo !== null) as CardInfo[];
    return (
        <div className="flex-col">
            <h2 className="home-title">StrangeBee Customer Community</h2>
            <div
                className={`${isPremiumUser ? "grid-4" : "grid-2"} grid-h-gapped`}
            >
                {cardsInfos.map((cardInfo, index) => (
                    <HomeCardCtaWithIcon
                        title={cardInfo.title}
                        text={cardInfo.text}
                        links={cardInfo.links}
                        icon={cardInfo.icon}
                        key={`home-card-${cardInfo.title}-${index}`}
                    />
                ))}
            </div>
        </div>
    );
};

export default CommunitySection;
