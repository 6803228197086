// -----------------------------------------------------
// dependencies
// -----------------------------------------------------
import { useState, useEffect, useContext } from "react";

import React from "react";
import LicenseNotification from "./Licenses/LicenseNotification";
import LicenseManager from "./Licenses/LicenseManager";
import SupportSection from "./Support/SupportSection";
import CommunitySection from "./Community/CommunitySection";
import LoadingScreen from "../../../components/Layouts/LoadingScreen/LoadingScreen";

import { CustomerOutput } from "../../../api/CustomerController";
import { UserOutput } from "../../../api/UserController";
import { LicenseOutput } from "../../../api/LicenseController";

import { renderStringDate } from "../../../helpers/dates";

import "./Home.scss";
import { ScopeContext } from "../../../context/ScopeProvider";
import HomeHeader from "./HomeHeader";

// -----------------------------------------------------
// Main component
// -----------------------------------------------------
type StatusType = "idle" | "error" | "loading" | "success";
interface HomeProps {
    customer: CustomerOutput;
    user: UserOutput;
    isLoading: boolean;
    customerQueryStatus: StatusType;

    licenses: LicenseOutput[];
    licensesQueryStatus: StatusType;
    isLicensesLoading: boolean;

    users: UserOutput[] | [];
    usersQueryStatus: StatusType;
    isUsersLoading: boolean;
}
const Home = ({
    customer,
    user,
    isLoading,
    licenses
}: HomeProps): JSX.Element => {
    const [headerInfo, setHeaderInfo] = useState<string>("");

    useEffect(() => {
        if (user?.last_login) {
            setHeaderInfo(
                user?.last_login
                    ? `Your last connection: ${renderStringDate(
                          user?.last_login
                      )}.`
                    : `You are now logged into StrangeBee License Portal`
            );
        }
    }, [user?.last_login]);

    const { expiringLicense } = useContext(ScopeContext);

    return (
        <div className="home">
            {isLoading ? (
                <LoadingScreen />
            ) : (
                <>
                    <HomeHeader
                        info={headerInfo}
                        title={`Welcome, ${
                            customer?.is_guest
                                ? user?.firstname
                                : user?.firstname
                        }!`}
                    />
                    <main className="home__grid">
                        {expiringLicense && (
                            <LicenseNotification
                                licenseTitle={
                                    expiringLicense.description ||
                                    (expiringLicense.id?.length > 10
                                        ? expiringLicense.id?.slice(0, 8) +
                                          "..."
                                        : expiringLicense.id)
                                }
                                expiryDate={renderStringDate(
                                    expiringLicense.expires_at
                                )}
                            />
                        )}

                        <LicenseManager licenses={licenses} />
                        <SupportSection />
                        <CommunitySection />
                    </main>
                </>
            )}
        </div>
    );
};

export default Home;
