import React, { useContext } from "react";
import "./SupportSection.scss";
import LinkCard from "../LinkCard";
import { HomeCardCta } from "../HomeCards";
import { ScopeContext } from "../../../../context/ScopeProvider";
import { URLS } from "../../../../api/urls";

const SupportSection = () => {
    const { isPremiumUser } = useContext(ScopeContext);
    return (
        <div className="flex-col">
            <h2 className="home-title">Technical support</h2>
            <div className="grid">
                <div
                    className={`flex-v-gapped ${
                        isPremiumUser ? "col-span-2" : "col-span-full"
                    }`}
                >
                    <LinkCard
                        text="TheHive documentation"
                        link={URLS.support.theHiveDoc}
                        className="align-start"
                    />
                    <LinkCard
                        text="API Documentation"
                        link={URLS.support.apiDoc}
                        className="align-start"
                    />
                    <LinkCard
                        text="Release notes"
                        link={URLS.support.releaseNotes}
                        className="align-start"
                    />
                </div>
                {isPremiumUser && (
                    <HomeCardCta
                        className="align-start"
                        title={"Contact technical support"}
                        ctaText="Open a request"
                        ctaLink={URLS.support.technicalSupport}
                    >
                        <p className="paragraph">
                            If you do not find the answer to your question in
                            the documentation, please do not hesitate to join
                            our Technical support team.
                        </p>
                    </HomeCardCta>
                )}
            </div>
        </div>
    );
};

export default SupportSection;
