// -----------------------------------------------------
// dependencies
// -----------------------------------------------------
import { useState, memo } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ReCAPTCHA from "react-google-recaptcha";
import useDisabledButton from "../../../hooks/useDisabledButton";

import FormInput from "../../Atoms/Form/Input/Input";
import Button from "../../Atoms/Button/Button";
import { iconMail } from "../../../images/pictograms";

import "./LoginForm.scss";
import "./ForgotPasswordForm.scss";
import { URLS } from "../../../api/urls";
import LoadingScreen from "../../Layouts/LoadingScreen/LoadingScreen";

// -----------------------------------------------------
// typescript rules
// -----------------------------------------------------
interface ForgotPasswordFormProps {
    onFormSubmit: Function;
    isLoading?: boolean;
}

export type ForgotPasswordFormValuesType = {
    email: string;
};

// -----------------------------------------------------
// form validation
// -----------------------------------------------------
const formSchema = yup.object().shape({
    email: yup.string().email().required("Email is required")
});

// -----------------------------------------------------
// component
// -----------------------------------------------------
const ForgotPasswordForm = ({
    onFormSubmit,
    isLoading
}: ForgotPasswordFormProps): JSX.Element => {
    const [token, setToken] = useState<string>();
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors }
    } = useForm<ForgotPasswordFormValuesType>({
        resolver: yupResolver(formSchema)
    });

    const { email } = watch();
    const { isButtonDisabled } = useDisabledButton([email, token], true);

    const handleFormSubmit = (values: ForgotPasswordFormValuesType) => {
        onFormSubmit(values);
    };

    const handleToken = async (value) => await setToken(value);

    return (
        <form
            autoComplete="off"
            className="loginForm ForgotPasswordForm"
            onSubmit={handleSubmit((data) => handleFormSubmit(data))}
        >
            <FormInput
                name="email"
                icon={iconMail}
                placeholder="Email"
                className={errors.email && "error"}
                error={errors.email?.message}
                register={register}
            />

            <Button
                className="button--primary button--block"
                disabled={isButtonDisabled}
            >
                {isLoading ? <LoadingScreen contained /> : "Continue"}
            </Button>

            <div className="loginForm__row--center">
                <ReCAPTCHA
                    sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY}
                    onChange={handleToken}
                />
            </div>

            <p>
                <Link to="/" className="formLink">
                    Go Back
                </Link>
            </p>

            <p>
                Still need help?{" "}
                <a href={URLS.website.contactPage} className="formLink">
                    Contact us
                </a>
            </p>
        </form>
    );
};

export default memo(ForgotPasswordForm);
