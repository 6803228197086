import { memo, ReactNode } from "react";

import "./QuotaGroup.scss";

interface QuotaGroupProps {
    children: ReactNode | ReactNode[];
}

const QuotaGroup = ({ children }: QuotaGroupProps): JSX.Element => (
    <div className="quota__group">{children}</div>
);

export default memo(QuotaGroup);
