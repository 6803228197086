import { memo, ReactNode } from "react";
import "./ErrorScreen.scss";

interface ErrorScreenProps {
    message: string;
    children?: ReactNode;
    icon?: ReactNode;
}

const ErrorScreen = ({
    message,
    children,
    icon
}: ErrorScreenProps): JSX.Element => (
    <div className="errorScreen">
        {icon}
        <p className="errorScreen__message">{message}</p>
        {children}
    </div>
);

export default memo(ErrorScreen);
