// -----------------------------------------------------
// Dependencies
// -----------------------------------------------------
import { useState } from "react";
import { useMutation } from "react-query";
import { useNavigate, useLocation } from "react-router-dom";
import { AxiosError } from "axios";

import DrawerLayout from "../../../components/Layouts/DrawerLayout/DrawerLayout";
import NewUserForm, {
    NewUserFormValuesType
} from "../../../components/Molecules/NewUserForm/NewUserForm";

import { CLIENT } from "../../../api/client";
import { queryClient } from "../../..";
import { MSG } from "../../../data/msg";

// -----------------------------------------------------
// Main component
// -----------------------------------------------------
interface DrawerFormUserProps {
    isOpen: boolean;
    setIsOpen: Function;
}
const DrawerFormUser = ({
    isOpen,
    setIsOpen
}: DrawerFormUserProps): JSX.Element => {
    const [errMsg, setErrMsg] = useState<string | null>();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const { mutate, error, reset, isLoading } = useMutation<
        any,
        Error,
        NewUserFormValuesType
    >((formValues) => CLIENT.UserController.create({ ...formValues }), {
        onSuccess: () => {
            pathname === "/portal/users"
                ? queryClient.refetchQueries(["get-all-users"])
                : navigate("/portal/users");

            setIsOpen(!isOpen);
        },
        onError: (err: AxiosError) => {
            if (err?.response.status === 500) {
                setErrMsg(MSG.err500);
            }

            if (err?.response.status === 400) {
                setErrMsg("Unable to create this user");
            }
        }
    });

    const resetDatas = () => {
        reset();
        setErrMsg(null);
    };

    const onFormSubmit = (formValues: NewUserFormValuesType) => {
        reset();
        mutate(formValues);
    };

    return (
        <DrawerLayout
            title="Add new user"
            error={errMsg ? errMsg : error?.message}
            reset={resetDatas}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            content={
                <NewUserForm
                    onFormSubmit={onFormSubmit}
                    callback={reset}
                    isLoading={isLoading}
                    isOpen={isOpen}
                />
            }
        />
    );
};

export default DrawerFormUser;
