import { memo, ReactNode } from "react";
import { classList } from "../../../helpers/className";

interface SettingCardBodyProps {
    className?: string | string[];
    children: ReactNode;
}

const SettingCardBody = ({
    className,
    children
}: SettingCardBodyProps): JSX.Element => (
    <div className={classList("settingCard__body", className)}>{children}</div>
);

export default memo(SettingCardBody);
