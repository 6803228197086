import { memo, ReactElement } from "react";

import "./TableTopRow.scss";

interface TableTopRowProps {
    className?: string;
    children?: ReactElement | ReactElement[];
}

const TableTopRow = ({ children }: TableTopRowProps) => (
    <div className="tableTopRow">{children}</div>
);

export default memo(TableTopRow);
