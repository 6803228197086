import { memo } from "react";
import "./CheckBox.scss";

interface CheckBoxProps {
    htmlFor?: string;
    label: string;
    name?: string;
    register?: any;
    checked?: boolean;
    onChange?: Function;
}

const CheckBox = ({
    htmlFor = "",
    label,
    name = "",
    register = () => {},
    onChange = () => {},
    checked
}: CheckBoxProps): JSX.Element => (
    <label htmlFor={htmlFor} className="formCheckbox__container">
        {label}
        <input
            type="checkbox"
            className="formCheckbox__box"
            name={name}
            onChange={onChange}
            checked={checked}
            {...register(name)}
        />
        <span className="formCheckbox__checkmark"></span>
    </label>
);

export default memo(CheckBox);
