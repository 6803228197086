export const classList = (...classElement: any[]): string => {
	const arrClass = classElement.reduce((acc, current) => {
		if (typeof current === "object") {
			if (!Array.isArray(current)) {
				current = Object.entries(current).reduce((acc, [key, value]) => {
					return value ? [...acc, key] : acc;
				}, []);
			}
			current = classList(...current);
		}

		return current ? [...acc, current] : acc;
	}, []);

	return arrClass.filter((e, i) => arrClass.indexOf(e) === i).join(" ");
};
